@import "../../../styles/base/variables";
.headertesttttt {
  background: #f3faff;
  display: flex;
  height: 60px;
  padding: 11px 80px;
  display: flex;
  .page_header {
    height: 96px;
    text-transform: uppercase;
    background: #f3faff;
    border-radius: 0px 0px 10px 10px;
    margin: 0 20px;
    display: flex;
    margin-bottom: 1rem;
    z-index: 0;

    &__icon {
      display: flex;
      width: 9%;
      justify-content: flex-end;
      position: relative;

      img,
      svg {
        height: auto;
        width: 27px;
        padding-right: 12px;
      }

      svg * {
        fill: $fiord;
      }
    }

    &__info {
      width: 51%;
      display: flex;
      align-items: center;
      padding-left: 19px;

      * {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        margin: 0;
        display: flex;
      }

      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #18a0fb;
        // font-size: 24px;
        // line-height: 34px;
        // color: $pickled-bluewood;
      }

      p {
        margin-left: 3px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #3c4e64;
        // font-size: 12px;
        // line-height: 18px;
        // color: #3c4e64;
      }
    }

    &__children {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5%;
      text-transform: initial;

      button {
        svg * {
          fill: #18a0fb;
        }
      }
    }
  }
}

.setting_page_header {
  background: #f3faff;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 11px 80px;

  &_button {
    &_label {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #6d7f92;
      line-height: 16px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-left: 10px;
    color: #2495e1;
  }

  &_name {
    font-weight: 500;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgb(41, 57, 77);
    margin-left: 1%;
  }
}
