.ttp-select__input input[type="text"] {
  height: auto;
  margin: 0;
}

.indicators {
  height: 28px;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3c4e64;
  //width: 95%;
  //margin-left: 3px;
  width: 88%;
  margin-left: 26px;

  &.created {
    .ttp-select__control {
      color: #3c4e64;
      background: #f8f9fa;
      border: 1px solid rgba(109, 127, 146, 0.2);
    }
  }

  .ttp-select__control {
    height: 28px;
    min-height: 28px;
    background: #f3faff;
    border: 1px solid #f3faff;

    &:hover {
      border: 1px solid #cfebff;
    }

    .ttp-select__value-container {
      height: 28px;
      min-height: 28px;

      //.ttp-select__placeholder,
      .ttp-select__single-value {
        top: 50%;
        color: #3c4e64;
      }

      & div:nth-child(2) {
        height: 25px;
        min-height: 25px;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
      }

      .ttp-select__input {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        input {
          box-shadow: none !important;
          margin-left: 0 !important;
        }
      }
    }

    .ttp-select__indicators {
      height: 28px;
      min-height: 28px;

      .ttp-select__indicator {
        padding-bottom: 10px;
        svg {
          width: 15px;
        }
      }
    }
  }


  // presentation preparation
  &.presenters_select {
    height: 32px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    //color: #f1f2f4;
    //width: 95%;
    //margin-left: 3px;
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    .ttp-select__control {
      height: 32px;
      min-height: 32px;
      background: #F8F9FA;
      border: 1px solid #B2BCC6;
      box-sizing: border-box;
      border-radius: 5px;

      .ttp-select__value-container {
        height: 30px;
        min-height: 30px;

        .ttp-select__placeholder {
          color: rgba(41, 57, 77, 0.38);
          font-weight: 400;
        }
        .ttp-select__single-value {
          top: 50%;
          color: #29394D;
        }

        & div:nth-child(2) {
          height: 32px;
          min-height: 32px;
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
        }

        .ttp-select__input {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: #29394D;
        }
      }

      .ttp-select__indicators {
        height: 32px;
        min-height: 32px;

        .ttp-select__indicator {
          padding-bottom: 10px;
          svg {
            width: 15px;
          }
        }
      }
    }
  }
}

.indicators_new {
  height: 38px;
  width: 90%;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #3c4e64;
  margin: auto;

  .ttp-select__control {
    height: 38px;
    min-height: 38px;
    background: #f8f9fa;
    border: 1px solid rgba(109, 127, 146, 0.2);

    &:hover {
      border: 1px solid #cfebff;
    }

    .ttp-select__single-value {
      color: #3c4e64;
      font-weight: 500;
    }
    .ttp-select__input {
      input {
        box-shadow: none !important;
        margin-left: 0 !important;
      }
    }
  }
}

.ttp-select {
  .ttp-select__menu {
    z-index: 10!important;
  }
}





