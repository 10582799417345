.ant-tooltip-inner {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  min-height: 22px !important;
  color: white;
  ul {
    list-style: inside;
  }
}
:global(.ant-tooltip) {
  font-size: 10px !important;
  font-family: Roboto;
}
