$search-border-radius: 30px;
$search-box-width: 253px;
$search-input-width: 300px;
$search-box-height: 32px;

.list-filter__bar {
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto;
}
.search-box {
  padding: 8px 13px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  
  background: #ffffff;
  border: 1px solid #E1E4E8;
  box-sizing: border-box;
  border-radius: $search-border-radius;
  height: $search-box-height;
  width: $search-box-width;

  input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: $search-input-width;
    //border-radius: 0 $search-border-radius $search-border-radius 0;
    border: none;
    outline: none;
    margin: 0;
    color: #3c4e64;
    &::placeholder {
      color: #b7bdc3;
      font-weight: 300;
      //font-family: Roboto;
      //font-style: normal;
      //font-weight: 300;
      //font-size: 14px;
      //line-height: 16px;
    }
  }
  .icon-tt-close {
    cursor: pointer;
    color: #b7bdc3;
    margin-right: 0.5rem;
  }
  &__icon:focus {
    outline: none;
  }

  &__icon {
    color: #b7bdc3;
    cursor: pointer;
    font-size: 1.2rem;
    display: inherit;
    margin: auto 0.5rem auto 0;
  }
}

.ttp-page-size {
  background: #f1f2f4;
  height: $search-box-height;
  border-radius: $search-border-radius;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c4e64;
  width: 79px;
  padding-top: 0;
  padding-bottom: 0;
  background-image: url("../../../../public/img/icon/polygon_up.svg");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 16px 7px;
  padding-right: 1.6rem;
  padding-left: 1.1rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  border: none;
  cursor: pointer;
  margin: 0;
}
