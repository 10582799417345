.page_content {
  color: #29394d;
  padding: 0.71rem 4.6%;
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;
  .content {
    display: flex;
    width: 100%;
    .sub_titles {
      // background-color: #18a0fb;
      color: #fff;
      width: 18%;
      margin: 11px;

      font-size: 14px;

      line-height: 16px;
      padding: 2rem 0rem;
      position: relative;
      .step {
        display: flex;
        padding: 10px;
        cursor: pointer;
        // border-bottom: 1px solid rgba(109, 127, 146, 0.3);
        .text {
          color: #b2bcc6;
          width: 94%;
          margin-right: 5px;
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(109, 127, 146, 0.3);
          .label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            text-transform: uppercase;
            width: 96%;
          }
        }
        .cercle {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          background-color: white;
          border: 4px solid #f1f2f4;
          border-radius: 50%;
          float: right;
          -webkit-filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
          filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
          z-index: 5;
          cursor: pointer;
          &.selected {
            background-color: #18a0fb;
            border: 4px solid white;
          }
        }
        span {
          position: absolute;
          margin-top: 17px;
          border-left: 4px solid #d8dde2;
          height: 33px;
          display: inline-block;
          z-index: 1 !important;
        }
      }
    }
    .bloc {
      width: 100%;
      margin: 11px;
      margin-bottom: 30px;
      min-height: auto;
      position: relative;
      background: #ffffff;
      border: 0.5px solid #f1f2f4;
      -webkit-box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      padding: 24px 28px;
      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-transform: uppercase;
        color: #29394d;
        margin: 10px 0px 10px 0px;
      }
      .description {
        margin-top: 5px;
        text-transform: capitalize;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #6d7f92;
      }

      .content {
        display: flex;
        justify-content: center;
        margin: 30px auto;
        .sub_title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-transform: capitalize;
          color: #29394d;
        }
        .client_types {
          margin: 0px auto;
          width: 21%;
          height: 100px;
          //   background-color: red;
          .selected_type {
            background: #18a0fb !important;
            color: white !important;
          }
          .types_labels {
            width: 220px;
            height: 32px;
            // background: #18a0fb;
            background: #f8f9fa;
            color: #6d7f92;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin: 10px 0px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height */

            display: flex;
            align-items: center;
            .type_label {
              margin: 5%;
            }
            .type_icon {
              margin: 6%;
              margin-left: auto;
            }
          }
        }

        .client_model {
          margin: 0px auto;
          width: 76%;
          background: #f8f9fa;
          border-radius: 5px;
          & > div {
            margin: 2%;
          }
          //   background-color: yellow;
          .types {
            display: flex;
            margin: 27px 0px 12px;
            .list_types {
              display: flex;
              .selected_modele {
                color: #18a0fb !important;
              }
              .item {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                // color: #18a0fb;
                color: #b2bcc6;
                width: 92px;
                justify-content: center;
                margin-right: 10px;
                margin-bottom: 6px;
                cursor: pointer;

                .label {
                  text-transform: capitalize;
                  justify-content: center;
                  display: flex;
                  margin-bottom: 6px;
                }
                .selected_modele {
                  background-color: #18a0fb !important;
                }
                span {
                  width: 92px;
                  height: 3px;
                  left: 0px;
                  top: 25px;

                  /* Bleu 1 */

                  //   background: #18a0fb;
                  border-radius: 5px;
                  width: 92px;
                  height: 3px;
                  //   background-color: #18a0fb;
                  background-color: #b2bcc6;
                  border-radius: 5px;
                  display: flex;
                }
              }
            }
            .selected_label {
              display: flex;
              margin-left: auto;
              .label {
                width: 234px;
                height: 34px;
                background: #f8f9fa;
                border: 1px solid #b2bcc6;
                border-radius: 5px;
                align-items: center;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #29394d;
                // margin: 5%;
              }
            }
            .new_modele {
              display: flex;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              border-right: 1px solid rgba(109, 127, 146, 0.3);
              line-height: 14px;
              color: #6d7f92;
              height: 28px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              width: 32px;
              margin: 0 1%;
              // }
            }
          }
        }
      }
    }
  }
}
.footer_actions {
  margin-left: auto;
  align-items: center;
  display: flex;
  margin-top: 26px;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 16px;
  border-bottom: none;
  justify-content: center;
  .button_next {
    background: #06d9b1;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin-bottom: 40px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: auto;
    cursor: pointer;
  }
  .button_prev {
    background: #18a0fb;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin-bottom: 40px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    background: #ffffff;
    color: #6d7f92;
    cursor: pointer;
  }
}

// to refactor and delete
// .page_content {
//   color: #29394d;
//   padding: 0.71rem 4.6%;
//   position: relative;
//   display: flex;
//   width: 100%;
//   margin: auto;
//   .content {
//     display: flex;
//     width: 100%;
//     .sub_titles {
//       // background-color: #18a0fb;
//       color: #fff;
//       width: 18%;
//       margin: 11px;

//       font-size: 14px;

//       line-height: 16px;
//       padding: 2rem 0rem;
//       position: relative;
//       .step {
//         display: flex;
//         padding: 10px;
//         cursor: pointer;
//         .text {
//           color: #b2bcc6;
//           width: 94%;
//           margin-right: 5px;
//           padding-bottom: 16px;
//           border-bottom: 1px solid rgba(109, 127, 146, 0.3);
//           .label {
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 14px;
//             line-height: 16px;
//             text-align: right;
//             text-transform: uppercase;
//             width: 96%;
//           }
//         }
//         .cercle {
//           height: 20px;
//           width: 20px;
//           display: flex;
//           justify-content: center;
//           background-color: white;
//           border: 4px solid #f1f2f4;
//           border-radius: 50%;
//           float: right;
//           -webkit-filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
//           filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
//           z-index: 5;
//           cursor: pointer;
//           &.selected {
//             background-color: #18a0fb;
//             border: 4px solid white;
//           }
//         }
//         span {
//           position: absolute;
//           margin-top: 17px;
//           border-left: 4px solid #d8dde2;
//           height: 33px;
//           display: inline-block;
//           z-index: 1 !important;
//         }
//       }
//     }
//     .bloc {
//       width: 78%;
//       margin: 11px;
//       margin-bottom: 30px;
//       min-height: 559px;
//       position: relative;
//       background: #ffffff;
//       border: 0.5px solid #f1f2f4;
//       -webkit-box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
//       box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
//       border-radius: 5px;
//       padding: 24px 28px;
//       .title {
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 18px;
//         line-height: 21px;
//         text-transform: uppercase;
//         color: #29394d;
//         margin: 10px 0px 18px 0px;
//       }
//       .info {
//         height: 60px;
//         border-left: 1px solid rgba(109, 127, 146, 0.3);
//         display: flex;
//         margin-bottom: 21px;
//         align-items: center;
//         .info_content {
//           margin: 23px;
//           width: 100%;
//           span {
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 12px;
//             line-height: 140%;
//             /* identical to box height, or 17px */

//             /* Dark 3 */

//             color: #6d7f92;
//           }
//           .value {
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 14px;
//             line-height: 16px;
//             text-transform: capitalize;

//             /* Dark 1 */

//             color: #29394d;
//           }
//         }
//       }
//       .volumetrie {
//         background: #f8f9fa;
//         border-radius: 5px;
//         padding: 24px 23px;
//         margin: auto;
//         span {
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 500;
//           font-size: 16px;
//           line-height: 19px;
//           text-transform: capitalize;
//           color: #29394d;
//         }
//         .list {
//           display: flex;
//           align-items: center;
//           border-bottom: 1px solid rgba(109, 127, 146, 0.3);
//           height: 40px;

//           .volumetrie_item {
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 12px;
//             line-height: 14px;
//             color: #29394d;
//           }
//           .value {
//             height: 22px;
//             align-items: center;
//             display: flex;
//             box-sizing: border-box;
//             border-radius: 5px;
//             background: #f1f2f4;
//             margin-left: auto;
//             width: 54px;
//             margin: auto;

//             .label {
//               margin: auto;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 500;
//               font-size: 12px;
//               line-height: 14px;
//               text-align: right;
//               color: #29394d;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .total_title {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500 !important;
//   font-size: 14px !important;
//   line-height: 16px;
//   display: flex;
//   align-items: center;
//   color: #29394d !important;
// }
// .line_buttom {
//   border-bottom: 1px solid #29394d !important;
// }

// .columns {
//   width: 97px;
//   align-items: center;
//   display: flex;
//   justify-content: flex-end;
// }
// .items_table {
//   justify-content: center;
//   display: flex;
//   align-items: center;
//   height: 100%;
//   // margin: 0;
//   // height: 100%;
//   // width: 60px;

//   width: 42px;
//   height: 61px;
//   background: #f1f2f4;
//   border-radius: 5px;
//   margin: auto;
// }
.input_box {
  margin: 0;
  width: 72.15px;
  height: 28px;
  margin: 0px 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3c4e64;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #b2bcc6;
  border-radius: 4px;
  text-align: right;
  margin-bottom: 3px;
}

.disabled {
  background: #f1f2f4;
  border: none;
  cursor: no-drop;
}

// .title_disabled {
//   display: flex;
//   margin: auto;
//   margin-bottom: 3px;
// }

// .active_step {
//   background-color: white !important;
//   border: 4px solid #18a0fb !important;
// }
// .active_label {
//   color: #18a0fb;
// }
