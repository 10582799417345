.error_container {
  .result_label {
    display: flex;
    align-items: center;
    .action {
      margin-right: 5px;
      border-radius: 3px;
      border: 1px solid var(--gris-3, #f1f2f4);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 30px;
      cursor: pointer;
    }
  }
  .content {
    display: inline;
    > div {
      &:first-child {
        display: flex;
        margin-top: 30px;
      }
    }

    .chart-pannel {
      padding: 0px;
      margin-top: 10px;
    }
  }
  .list {
    width: 20%;
    margin: 0px 10px 0px 0px;
    .tag-item {
      display: flex;
      height: 54px;
      padding: 0px 10px;
      margin-bottom: 10px;
      align-items: center;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid var(--gris-3, #f1f2f4);
      background: #fff;
      box-shadow: 0px 0px 5px 0px rgba(41, 57, 77, 0.10);

      .name {
        font-weight: 400;
      }
      .avatar {
        margin-right: 10px;
      }
    }
    .active {
      border: 1px solid var(--bleu-1-principale, #18A0FB);
      background: var(--bleu-ciel-1, #F3FAFF);
    }
  }
  .body-content {
    width: 58%;
  }
}

.custom-tooltip-2 {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  border: 1px solid #d8dde2;
  padding: 10px;
  font-family: Roboto;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0em;
  span {
    font-size: 11px;
    color: #29394d;
  }
  p {
    margin-left: 10px;
  }
}