$light-gray: #f2f3f4;
$normal-gray: #dee2e6;
$transition-time: 300ms;

@mixin flex-center($content, $items) {
  display: flex;
  justify-content: $content;
  align-items: $items;
}

.react_table {
  min-width: 1300px;
  max-width: 1800px;
  border-spacing: 3px;
  border-collapse: separate;
  font-size: 12px;

  thead {
    // background: #f8f8f8;
    color: #0a0a0a;
    
  }
  th {
    min-height: 20px;
    background: transparent;
  }
  tbody {
    font-weight: 700;
  }

  tr {
    &:nth-child(1),
    &:nth-child(2) {
      th {
        color: #fff;
        background-color: #3c4e64;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        height: 38px;
      }
    }
    &:first-child {
      th {
        border-radius: 4px;
        &:first-child {
          background-color: white;
        }
      }
    }

    &:nth-child(2) {
      th {
        &:nth-child(2n) {
          border-radius: 4px 0 0 4px;
        }

        &:nth-child(2n + 1),
        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &:first-child {
          border-radius: 4px;
          width: 18%;
        }

        &:nth-child(9),
        &:nth-child(10) {
          border-radius: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 11%;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 9%;
        }
        &:nth-child(6),
        &:nth-child(7) {
          width: 14%;
        }

        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11) {
          width: 4%;
        }
      }
    }

    td {
      padding: 0.4rem 0.3rem;
      color: #3c4e64;

      &:nth-child(2n) {
        background-color: $light-gray;
        border-radius: 4px 0 0 4px;
      }

      &:nth-child(2n + 1),
      &:last-child {
        background-color: $normal-gray;
        border-radius: 0 4px 4px 0;
      }

      &:nth-child(10) {
        background-color: $normal-gray;
        border-radius: 0px;
      }

      &:first-child {
        max-width: 263px;
        background-color: $light-gray;
        border-radius: 4px;
        padding: {
          right: 0.8em;
          left: 0.8em;
        }

        & > div {
          // @include flex-center(space-between, center);

          & > div {
            padding: 0.3em 0.4em 0 0.4em;
            background: $normal-gray;
            border-radius: 5px;
            cursor: pointer;
            transition: $transition-time;

            svg {
              width: 16px;
              height: 18px;
            }
          }

          p {
            max-width: 216px;
            margin: 0;
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;

            &:hover {
              text-indent: calc(attr(data-length));
            }
          }
        }
      }

      &:nth-child(8),
      &:nth-child(9) {
        background-color: $normal-gray;
      }

      &:nth-child(9) {
        border-radius: 0;
      }

      input {
        width: 60px !important;
        height: auto;
        margin: 0;
        padding: 3px 0 0 0;
        text-align: center;
        font-size: 11.5px;
        font-weight: inherit;
        box-sizing: border-box;
        color: #3c4e64;
        background: #ffffff;
        border: none;
        border-radius: 4px;
        transition: $transition-time;
        box-sizing: border-box;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:focus {
          border: 1px #18a0fb solid;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }
}
