.progress_item {
  display: flex;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin-bottom: 4px;
  height: 112px;
  padding: 16px 26px;
  position: relative;

  &_progress {
    width: 200px;
    padding: 0px 18px;
    &_info {
      display: flex;
      justify-content: space-between;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #6d7f92;
    }

    &_container {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: rgba(109, 127, 146, 0.3);
    }
    &_bar {
      height: 4px;
      background: #29394d;
      border-radius: 3px;
    }
    &_index {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Roboto";
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      svg {
        transform: rotate(180deg);
      }
      .realized_progress {
        text-align: center;
        width: 83px;
      }
    }
  }
}
.tags {
  justify-content: space-around;
}

.content-body .production-frame .tags .labled-tag .tag {
  margin-left: 0px;
  margin-bottom: 0px;
}
.content-body .production-frame .matrix_title_header {
  margin-top: 30px;
}
.content-body .production-frame .tags .labled-tag.no-border {
  margin-left: 0px;
  margin-bottom: 0px;
}

.CustumedMatrix_matrix_body_line__11lbb:first-child {
  border-bottom: 1px solid #d8dde2;
}
.p-carousel {
  width: 95%;
}
.p-carousel .p-carousel-indicators {
  padding: 7px;
  display: none;
}

.tab_badge {
  &_active {
    border-color: #18a0fb;
    color: #18a0fb;
    span {
      background: #18a0fb !important;
    }
  }
}
.production-frame_scroll::-webkit-scrollbar {
  display: none;
}

// ------ Skeleton Styles
.skeleton {
  border-radius: 5px;
  padding: 6px 5px;
  background-color: #f1f2f4;
  // The shine that's going to move across the skeleton:
  background-image:
          linear-gradient(
              90deg,
              rgba(#fff, 0),
              rgba(#fff, 0.5),
              rgba(#fff, 0)
          );
  background-size: 40px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

.tag-skeleton {
  width: 72px;
  height: 28px;
}

.title-skeleton {
  width: 200px;
  height: 28px;
}

@keyframes shine {
  to {
      // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
      background-position: right -40px top 0;
  }
}
