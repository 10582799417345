.line_fetching {
  display: flex;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 112px;
  padding: 16px 26px;

  .infos {
    width: 250px;
    height: 100%;
    border-right: 1px solid #e1e4e8;
    padding-top: 5px;

    &_header {
      width: 190px;
      height: 19px;
      margin-bottom: 7px;
      border-radius: 5px;
    }

    &_sub_header {
      width: 130px;
      height: 16px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
  }

  .state {
    width: 83px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e1e4e8;

    &_icon {
      height: 12px;
      width: 12px;
      margin-bottom: 5px;
      border-radius: 3px;
    }
    &_label {
      width: 45px;
      height: 14px;
      border-radius: 3px;
    }
  }

  .progress {
    width: 200px;
    padding: 0px 18px;
    padding-top: 7px;
    border-right: 1px solid #e1e4e8;
    &_info {
      display: flex;
      justify-content: space-between;
      height: 18px;

      > div {
        width: 30px;
        border-radius: 5px;

        &:first-child {
          width: 90px;
        }
      }
    }

    &_container {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: rgb(238, 238, 238);
    }

    &_icon {
      width: 12px;
      height: 12px;
      margin-top: 7px;
      border-radius: 3px;
    }
    &_index {
      margin-top: 3px;
      width: 20px;
      height: 12px;
    }
  }

  .tags {
    width: 194px;
    display: flex;
    align-items: center;
    border-right: 1px solid #e1e4e8;
    .tag {
      height: 20px;
      margin: 0 auto;
      width: 146px;
      border-radius: 5px;
    }
  }

  .avatars {
    padding: 0px 20px;
    display: flex;
    align-items: center;

    .avatar {
      position: relative;
      width: 34px;
      height: 34px;
      border: 1px white solid;
      border-radius: 50%;

      &:nth-child(2) {
        z-index: 2;
        right: 10px;
      }
      &:nth-child(3) {
        z-index: 3;
        right: 20px;
      }
      &:nth-child(4) {
        z-index: 4;
        right: 30px;
      }
      &:nth-child(5) {
        z-index: 5;
        right: 40px;
      }
    }
  }
}

.no_data {
  text-align: center;
  margin: 4rem 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 63vh;
  font-family: Roboto;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}
.list-frame_item_body {
  background: #f8f9fa;
  display: block;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  .title_details {
    color: #29394d;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px;
  }
}
.header {
  height: 69px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  // border-bottom: 1px solid #F1F2F4;
}

.client-custom-tooltip {
  display: flex;
  width: 176px;
  padding: 11px 10px 12px 10px;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;
  .title {
    width: 100%;
    color: #29394d;
    text-align: right;
    font-size: 4px;
    margin-bottom: 7px;
  }
  .details {
    height: 36px;
    // width: 109px;
    padding-right: 7px;
    border-right: 1px solid #e1e4e8;
    color: #6d7f92;
    text-align: right;
    font-size: 12px;
    > div {
      margin: 2px 0px;
      &:first-child {
        color: #02af8e;
        margin-bottom: 4px;
      }
    }
  }
}
.cartes {
  cursor: pointer;
  padding: 15px;
  width: 95% !important;
  transition: all 0.3s linear !important;
  height: 150px;
  border: 1px solid var(--hover-color) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  margin: 7px;
  .title_cartes {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    color: #29394d;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 3px;
  }
  .chart {
    height: 100px;
    display: flex;
    align-items: center;
  }
  .roles {
    justify-content: space-between;
    display: flex;
    // width: 96%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #6d7f92;
    &:hover {
      background: #f1f2f4 !important;
    }
    .percentage-success {
      color: #02af8e;
    }
    .percentage-medle {
      color: rgb(234, 129, 36);
    }
    .percentage-danger {
      color: rgb(222, 72, 72);
    }
  }
  .roles_info {
    width: 88% !important;
  }
  .dates_info {
    display: flex;
    margin-right: auto;
  }
  span {
    justify-content: center;
    display: flex;
    // width: 96%;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #6d7f92;
  }
  // .roles:hover {
  //   background: #f1f2f4 !important;
  // }
  .group_roles {
    margin: auto;
    width: 80%;
    // background: linear-gradient(0deg, #ffffff, #ffffff), #d0fff6;
    // background: red !important;
    // background: linear-gradient(0deg, #f1f2f4, #f1f2f4), #d0fff6 !important;
  }
}
.gradiant_cartes {
  height: 150px;
  width: 95%;
  border: 1px solid #f1f2f4 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  transition: all 0.3s linear !important;
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}
