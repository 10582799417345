.content-body {
  .production-frame {
    padding-bottom: 40px;
    overflow-y: scroll;
    .total {
      margin: 0px 0px -20px 24px;
      color: var(--Dark-1, #29394d);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &_scroll {
      margin-top: 35px;
      max-height: 600px;
      overflow: scroll;
    }
    #ttp-horizontal-filter {
      border-bottom: none;
      margin-top: 0px;
      .filter-form {
        background: #f8f9fa;
        margin-top: 0px;
      }
    }
    &_tabs {
      display: flex;
      margin: 30px 0px;
      width: 100%;
      display: flex;
      margin: 30px auto;
      align-items: center;
      justify-content: space-around;
      padding: 0px 15px;

      .p-carousel {
        width: 81%;
        .p-carousel-indicators {
          display: none;
        }

        button {
          &:focus {
            box-shadow: 0 0 0 0;
          }
        }
      }

      .tab_tag {
        width: fit-content;
        height: 20px;
        flex-shrink: 0;
        color: #6d7f92;
        background: #f1f2f4;
        font-size: 12px;
        font-weight: 500;
        padding: 0px 7px;
        border-radius: 5px;
        margin-top: 6px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &_active {
          background: #c7e8fe;
        }
      }
      .total {
        width: 160px;
        height: 50px;
        flex-shrink: 0;
        color: #29394d;
        font-size: 14px;
        line-height: normal;
        font-weight: 500;
        border-left: 1px solid #e1e4e8;
        padding: 2px 17px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > div {
          width: 100%;
          display: flex;
          margin-bottom: 3px;
          align-items: flex-end;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
      }
    }
    .CustumedMatrix_matrix_body_line__11lbb {
      &:hover {
        background: #f8f9fa;
        cursor: pointer;
        .action_doh {
          opacity: 1 !important;
        }
      }
      > div {
        &:first-child {
          min-width: 24%;
          height: 73px;
        }
      }

      span {
        width: 53px !important;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        margin-bottom: 4px;
      }
      & > div {
        padding: 10px 5px 0px;
      }
    }
    .non-hover-line {
      &:hover {
        background: #fff;
        cursor: default;
      }
    }
    .progress-bar {
      width: 100%;
      border-right: none;
      position: relative;

      font-family: "Roboto";
      flex-direction: column;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;

      &_container {
        width: 100%;
        height: 28px;
        border-radius: 3px;
        background: #e1e4e8;
      }
      &_bar {
        height: 28px;
        background: #06d9b1;
        border-radius: 3px;
      }
      .progress_total {
        position: absolute;
        top: 9px;
        right: 2px;
        color: #6d7f92;
      }
      .progress_index {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        color: #02af8e;
      }
    }
    .tags {
      display: flex;
      margin: 0px 10px;
      justify-content: space-between;

      .tag {
        margin: 11px 0;
        padding: 0px 15px 0px 5px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        border-left: 1px solid #e1e4e8;
        color: #6d7f92;
        position: relative;

        > div {
          &:first-child {
            // margin: 0px 5px;
            width: 72px;
            height: 28px;
            border-radius: 5px;
            padding: 6px 5px;
            background: #f1f2f4;
            text-align: end;
          }
        }

        .notif-pop {
          width: 8px;
          height: 8px;
          background: #de4848;
          border-radius: 4px;
          position: absolute;
          top: -3px;
          right: 17px;
        }

        input {
          height: 23px;
          font-size: 12px;
          line-height: 14px;
          color: #3c4e64;
          box-shadow: none;
          padding: 5px;
          background: #ffffff;
          border: 1px solid #b2bcc6;
          border-radius: 4px;
          text-align: right;
          margin-bottom: 3px;
          width: 72px;
        }

        .progress_item {
          display: flex;
          background: #ffffff;
          border: 1px solid #f1f2f4;
          border-radius: 5px;
          margin-bottom: 4px;
          height: 112px;
          padding: 16px 26px;
          position: relative;

          &_progress {
            width: 200px;
            padding: 0px 18px;
            &_info {
              display: flex;
              justify-content: space-between;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 160%;
              color: #6d7f92;
            }

            &_container {
              width: 100%;
              height: 4px;
              border-radius: 3px;
              background: rgba(109, 127, 146, 0.3);
            }
            &_bar {
              height: 4px;
              background: #29394d;
              border-radius: 3px;
            }
            &_index {
              display: flex;
              align-items: center;
              justify-content: center;
              font-family: "Roboto";
              flex-direction: column;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              svg {
                transform: rotate(180deg);
              }
              .realized_progress {
                text-align: center;
                width: 83px;
              }
            }
          }
        }
      }
      .labled-tag {
        height: 65px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .tag {
          margin: 15px 0px 0px;
          // padding-left: 5px;
        }
      }
      .no-border {
        border: 0px;
        .tag {
          border: 0px;
          padding: 0px;
          & > div {
            margin: 0px;
          }
        }
      }
      .sm-margin {
        margin: 5px;
      }
      .lg-tag {
        padding: 0px;
        div:first-child {
          width: 86px;
        }
      }
      .xl-tag {
        padding: 0px;
        margin-left: 10px;
        div:first-child {
          text-align: center;
          width: 120px;
        }
      }

      .labled-tag.no-border {
        margin-left: 8px;
        margin-bottom: 10px;
        text-transform: capitalize;
      }
      .tva {
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 0 0 !important;
      }

      span {
        width: 53px !important;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        margin-bottom: 5px;
      }
      .tag-success {
        > div {
          background: rgba(6, 217, 177, 0.1) !important;
          color: #02af8e !important;
        }
      }
      .tag-warning {
        > div {
          background: #ea81241a !important;
          color: #d87318 !important;
        }
      }
      .tag-danger {
        > div {
          background: rgba(235, 87, 87, 0.1) !important;
          color: #eb5757 !important;
        }
      }
    }

    .text-tag {
      padding: 0px 5px 0px 5px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      align-items: center;
      background: #f1f2f4;
      color: #6d7f92;
      position: relative;
      display: flex;
      border-radius: 5px;
      min-height: 40px;
    }

    .matrix_title {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 500;
      justify-content: space-between;

      .title_infos {
        display: flex;
        width: 68%;
        > div {
          &:first-child {
            border-radius: 50%;
            height: 13.5px;
            min-width: 13.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 1px 1px 0px;
            margin-right: 5px;
          }
        }
        .warning {
          background: #ea8124;
          > svg {
            transform: rotate(180deg);
          }
        }
        .danger {
          background: #eb5757;
          > svg {
            transform: rotate(-90deg);
          }
        }
        .success {
          background: #06d9b1;
          > svg {
            transform: rotate(90deg);
          }
        }
      }

      .actions {
        display: flex;
        .title_action {
          max-width: 140px !important;
          height: 28px;
          padding: 4px 6px;
          border-radius: 5px;
          border: 1px solid #6d7f92;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-right: 5px;

          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: right;

          &:hover {
            background: #ffffff;
            cursor: pointer;
            color: #18a0fb;
            svg {
              * {
                stroke: #18a0fb;
              }
            }
          }

          > svg {
            margin-right: 3px;
          }
        }

        .action_doh {
          opacity: 0;
          transition: opacity 0.5s;
        }
      }

      &_header {
        display: flex;
        align-items: center;
        margin-top: 60px;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
    .sm-header {
      margin-top: 0px;
      justify-content: space-between;
    }

    .matrix_sub_title {
      padding: 4px 0px;
      .title_infos {
        height: 42px;
        font-family: Roboto;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .chart-pannel {
    display: flex;
    justify-content: space-between;
    padding: 0px 17px;
    .chart-frame {
      width: 584px;
      height: 500px;
      border-radius: 5px;
      border: 1px solid #e1e4e8;
      padding: 18px 24px;
      .title {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        color: #29394d;
      }
      .labels {
        font-family: Roboto;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        height: 45px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b2bcc6;
        padding: 7px 0px 0px;

        .label {
          display: flex;
          align-items: center;
          min-width: 100px;
          border-right: 1px solid #b2bcc6;
          padding: 0px 10px;

          .dot {
            width: 8px;
            height: 7.8125px;
            border-radius: 50px;
            background-color: #b2bcc6;
            margin-right: 5px;
          }
          .line {
            width: 8px;
            height: 1px;
            margin-right: 5px;
            border: 1px solid #000000;
          }
        }
      }
      .body {
        padding: 0px 10px 50px 0px;
        font-family: Roboto;
        letter-spacing: 0em;
        position: relative;
        margin-left: -21px;

        &_titles {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 30px 0px 20px 40px;
          > div {
            font-size: 12px;
            font-weight: 600;
            line-height: 19px;
            color: #29394d;
          }
        }
        .line_chart {
          position: absolute;
          top: 39px;
        }
      }
    }
  }
  .recharts-cartesian-axis-ticks {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
  }
  .recharts-surface {
    overflow: visible;
  }
  .yAxis {
    & > line {
      display: none;
    }
  }
  .custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    border: 1px solid #d8dde2;
    padding: 10px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
  }

  .config-sid {
    overflow-y: scroll !important;
    .CustumedMatrix_matrix_body_line__11lbb {
      > div {
        &:first-child {
          height: 50px !important;
        }
      }
    }
  }
}
.sidebar {
  &_header {
    .tag {
      width: 210px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 10px;
      color: #29394d;
      text-transform: uppercase;
      margin: 0px 5px 0px 0px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
      border: 0.4px solid #b2bcc6;
      cursor: pointer;

      & > div {
        width: 24px !important;
        height: 24px !important;
        border-radius: 4px;
        background: #b2bcc6 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .active {
      background: #6d7f92;
      color: #ffffff;
    }
  }
  &_galery {
    display: flex;
    padding: 10px 10px 10px 26px;
    margin: 0px 0px 10px;
    width: 100%;
    overflow-x: scroll;

    .carte {
      max-width: 310px;
      border-radius: 5px;
      margin: 0px 5px;
      padding:  10px;
      box-shadow: 0px 4px 10px 0px #29394d1a;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-grow: 1;
      gap: 4px;

      color: #29394d;
      font-size: 12px;
      line-height: 20px;

      & > div {
        &:first-child {
          font-weight: 500;
          // position: absolute;
          line-height: 16px;
          // top: 11px;
        }
        &:nth-child(2) {
          // position: absolute;
          display: flex;
          // bottom: 5px;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }
      }

      &.active {
        background: #29394d;
        color: #ffffff;
        &:hover {
          background: #29394d;
        }
      }

      &.blocked {
        cursor: no-drop;
      }
      .action {
        display: flex;
        opacity: 0;
        transition: all 0.2s linear;
        transform: translateY(-4%);
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 3px;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
        background: #ffffff;
        color: #18a0fb;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

        &:hover {
          background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
          z-index: 1;
          svg {
            color: #ffffff;

            * {
              fill: #ffffff;
            }
          }
        }
      }
      &:hover {
        background: #f1f2f4;
        .action {
          opacity: 0;
        }
      }
    }
  }
  &_body {
    margin: 10px 0px 0px;
    padding: 0px 25px 0px 29px;
    display: flex;
    width: 100%;
    .aside {
      width: 21.5%;
      border-radius: 0px 0px 5px 5px;
      background: #f8f9fa;
      padding: 5px 15px;
      height: fit-content;
      padding-bottom: 40px;

      .infos-set {
        font-family: Roboto;
        line-height: 20px;
        font-size: 12px;
        letter-spacing: 0em;
        display: flex;
        flex-direction: column;
        padding: 15px 0px 5px;
        border-bottom: 1px solid #d8dde2;
        label {
          font-weight: 400;
          color: #6d7f92;
        }
        span {
          font-weight: 600;
          color: #29394d;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            visibility: hidden;
            cursor: pointer;
          }
          &:hover {
            svg {
              visibility: visible;
            }
          }
        }
        .avatars {
          text-align: center;
          align-items: center;
          justify-content: flex-start;
          display: flex;
          width: 33%;

          .avatar {
            position: relative;
            $elements: 15;
            @for $i from 0 to $elements {
              &:nth-child(#{$i + 1}) {
                z-index: $i + 1;
                right: $i * 7px;
              }
            }
            &_exist {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              border: 2px solid #e1e4e8;
              background-size: cover;
              background-position: top center;
            }

            &_not_exist {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
              font-size: 12px;
              font-weight: 600;
              margin: auto;
              color: white;
              border: 1px white solid;
              border-radius: 50%;
              background-color: darkgray;
              cursor: default;

              & > span {
                margin: auto;
                color: white;
              }
            }
          }
        }
      }
      &-center {
        padding: 20px 46px 40px;
        display: flex;
        text-align: center;
        flex-direction: column;

        label {
          color: #6d7f92;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 12px;
        }
        .avatar {
          .avatar_not_exist,
          .avatar_exist {
            margin: 0 auto;
            width: 65px;
            height: 65px;
            font-size: 22px;
          }
        }
        .infos {
          color: #29394d;
          font-size: 16px;
          font-weight: 500;
          margin: 10px 0px;
        }
      }
    }
    .content {
      margin-left: 10px;
      width: 78%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0px 0px 0px 10px;
      .tags {
        height: 38px;
        width: 98%;
        border-bottom: 1px solid #e1e4e8;
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        position: relative;
        justify-content: flex-start;
        svg {
          margin-right: 5px;
          cursor: pointer;
        }
        .tag {
          height: 30px;
          padding: 9px 12px 9px 12px;
          border-radius: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #f1f2f4;
          color: #6d7f92;
          margin: 0px 5px;

          font-family: Roboto;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.02em;
          cursor: pointer;

          &.active {
            background: #6d7f92;
            color: #ffffff;
          }

          &.success {
            background: #18a0fb;
            color: #ffffff;
          }

          .dot {
            width: 6px !important;
            height: 6px !important;
            margin-right: 5px !important;
            border-radius: 3px !important;
            padding: 0px !important;
          }
        }

        .action {
          height: 12px;
          padding-left: 8px;
          border-left: 1px solid #e1e4e8;
          display: flex;
          position: absolute;
          right: 0px;
        }
      }
      .light_frame {
        width: 100%;
        & > div {
          display: flex;
        }
        .set {
          display: flex;
          flex-direction: column;
          margin-bottom: 17px;
          label {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #6d7f92;
          }
        }
        input,
        textarea {
          width: 290px;
          border-radius: 5px;
          border: 1px solid #b2bcc6;
          background: #f8f9fa;
          padding: 0px 10px;
          color: #29394d;
          margin: 5px 0px;

          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
        }
        input {
          height: 34px;
        }
        textarea {
          height: 100px;
        }
        .frame {
          width: 50%;
          font-family: Roboto;
          letter-spacing: 0em;
          padding: 6px 0px 0px;
          .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: #29394d;
            margin: 10px 0px;
          }
        }
        .panel {
          width: 50%;
          border-left: 1px solid #e1e4e8;
          margin: 46px 0px 0px;
          padding-left: 10px;

          .rectangular_select {
            &_header {
              width: 290px;
              height: 34px;
            }
            &_body {
              & > input {
                width: 290px;
                min-height: 30px !important;
                margin: -1px 0px 0px;
                border-radius: 4px;
              }
              width: 290px;
            }
          }
          .calendar_select {
            .p-calendar {
              height: 34px;
              margin-top: 5px;
            }
            .p-inputtext {
              border-width: 1px 0px 1px 1px;
              border-radius: 4px 0px 0px 4px;
              border-color: #b2bcc6;
              background: #f8f9fa;
              width: 258px;
              margin: 0px;

              &:focus {
                box-shadow: none;
              }
            }
            .p-button {
              background: #f8f9fa;
              color: #6d7f92;
              border-width: 1px 1px 1px 0px;
              border-color: #b2bcc6;
              border-radius: 0px 4px 4px 0px;

              &:focus {
                box-shadow: none;
              }
            }
            .p-button.p-button-icon-only {
              width: 2rem;
            }
          }
        }
      }
      .heavy_frame {
        overflow-y: scroll;
        height: 450px;
        & > div {
          display: flex;
        }
        .frame {
          width: 69%;
          font-family: Roboto;
          letter-spacing: 0em;
          padding: 30px 10px 0px 0px;
          &_title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .title {
              font-size: 20px;
              font-weight: 500;
              line-height: 23px;
              color: #29394d;
              margin-right: 5px;
              padding: 0px 0px 5px 5px;
              &_sub {
                font-size: 16px;
              }
            }
            .smallAction {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              color: #6d7f92;
              cursor: pointer;
              margin-top: 4px;
            }
            &_sub {
              margin-bottom: 0px;
            }
          }
          input,
          textarea {
            border-radius: 5px;
            width: 400px;
            border: 1px solid #b2bcc6;
            background: #f8f9fa;
            padding: 0px 10px;
            color: #29394d;
            font-weight: 400;
            line-height: 17px;
            height: 34px;
            font-size: 19px;
          }
          input {
            font-weight: 500;
          }
          textarea {
            height: 100px;
            font-size: 12px;
          }
          .b-frame {
            padding: 10px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #8d8d8d;
            margin-bottom: 20px;
            p {
              color: #29394d;
            }
            &:hover {
              background: #f1f2f4;
              cursor: text;
            }
          }

          .doted_items {
            .doted_item {
              width: 362px;
              height: 34px;
              border-radius: 5px;
              padding: 7px;
              border: 1px dashed #e1e4e8;
              margin: 4px 0px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              font-size: 12px;
              line-height: 14px;
              color: #29394d;

              .actions {
                opacity: 0;
                height: 12px;
                overflow: hidden;
                transition: opacity 0.5s;

                & > span {
                  cursor: pointer;
                  &:last-child {
                    margin-left: 5px;
                    border-left: 1px solid #e1e4e8;
                    padding-left: 5px;
                  }
                }
              }

              &:hover {
                .actions {
                  opacity: 1;
                }
              }
            }
          }

          .time_line {
            .event {
              display: flex;
              .at {
                display: flex;
                flex-direction: column;
                font-family: Roboto;
                font-size: 10px;
                padding-top: 4px;
                font-weight: 400;
                letter-spacing: 0em;
                text-align: right;
                color: #29394d;
                font-style: normal;
                line-height: normal;
              }
              .line {
                margin: 0px 15px;
                position: relative;
                width: 2px;
                height: 42px;
                background: #d8dde2;
                .dot {
                  position: absolute;
                  top: 10px;
                  width: 12px;
                  height: 12px;
                  right: -5px;
                  border-radius: 50%;
                  background: #29394d;
                  border: 2px solid #d8dde2;
                }
              }
              .details {
                display: flex;
                flex-direction: column;
                padding: 6px 0px;
                font-family: Roboto;
                font-size: 12px;
                text-align: left;
                color: #6d7f92;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                > span {
                  &:first-child {
                    font-weight: 500;
                  }
                }
                span {
                  color: #29394d;
                }
              }
            }
          }
        }
        .panel {
          width: 30%;
          border-left: 1px solid #e1e4e8;
          margin: 30px 0px 0px;
          padding-left: 10px;
          .title {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 16px;
            color: #29394d;
            margin: 4px 0px;
            svg {
              margin-right: 5px;
            }
            span {
              font-weight: 600;
            }
            div {
              color: #6d7f92;
              font-weight: 400;
              margin-right: 10px;
            }
          }
          .list_users {
            margin: 11px 0px 25px;
            .user {
              display: flex;
              margin: 8px 0px;
              align-items: center;
              font-size: 12px;
              line-height: 16px;
              font-family: Roboto;
              position: relative;
              padding: 3px 0px;
              border-radius: 5px;

              .avatar {
                background: linear-gradient(#06d9b1, #18a0fb);
                border-radius: 50px;
                padding: 3px;
                .avatar_not_exist {
                  border: 0px;
                }
                .avatar_exist {
                  border: 0px;
                }
              }
              .infos {
                margin: 0px 10px;
                font-size: 12px;
                // font-weight: 500;
                line-height: 14px;
                color: #29394d;
                display: flex;
                flex-direction: column;
              }
              .action {
                display: flex;
                opacity: 0;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 5px;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                cursor: pointer;
                background: #ffffff;
                color: #18a0fb;
                box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

                &:hover {
                  background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
                  z-index: 1;
                  svg {
                    color: #ffffff;

                    * {
                      fill: #ffffff;
                    }
                  }
                }
              }
              &:hover {
                background: #f8f9fa;
                .action {
                  opacity: 1;
                }
              }
            }
          }
          .rectangular_select {
            .p-calendar {
              height: 28px;
            }
            .p-inputtext {
              border-width: 1px 0px 1px 1px;
              border-color: #b2bcc6;
              background: #f8f9fa;
              color: #29394d;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: capitalize;

              &:focus {
                box-shadow: none;
              }
            }
            .p-button {
              background: #f8f9fa;
              color: #6d7f92;
              border-width: 1px 1px 1px 0px;
              border-color: #b2bcc6;
              border-radius: 0px 4px 4px 0px;

              &:focus {
                box-shadow: none;
              }
            }
            .p-button.p-button-icon-only {
              width: 2rem;
            }
          }
          .list_tags {
            display: flex;
            padding-right: 8px;
            margin-bottom: 25px;
            justify-content: flex-start;
            .tag {
              width: 50px;
              height: 22px;
              padding: 4px 10px 4px 10px;
              border-radius: 5px;
              background: #f1f2f4;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-right: 10px;
              color: #6d7f92;

              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
          }
        }
      }
      .pre-form {
        display: flex;
        flex-direction: column;
        .instruction {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          color: #6d7f92;
          margin: 8px 5px 10px;
        }
      }
      .grid_card {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        height: 400px;
        padding-bottom: 105px;

        .card {
          width: 201px;
          min-height: 45px;
          border-radius: 5px;
          margin: 5px 5px 5px 0px;
          min-width: 30%;
          padding: 0px 10px;
          box-shadow: 0px 4px 10px 0px #29394d1a;

          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          color: #29394d;
          font-size: 12px;
          line-height: 20px;

          & > div {
            &:first-child {
              font-weight: 600;
            }
          }

          &:hover {
            cursor: pointer;
            opacity: 0.9;
            .action-side {
              .actions {
                .action {
                  opacity: 1;
                }
              }
            }
          }

          &.active {
            background: #29394d;
            color: #ffffff;
          }

          .action-side {
            display: flex;
            justify-content: center;
            align-items: center;

            .actions {
              display: flex;
              margin-right: 10px;
              .action {
                display: flex;
                opacity: 0;
                transition: all 0.2s linear;
                justify-content: center;
                align-items: center;
                width: 1.75rem;
                height: 1.75rem;
                border-radius: 50%;
                cursor: pointer;
                background: #ffffff;
                color: #18a0fb;
                box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

                &:nth-child(2) {
                  right: 14px;
                }

                &:hover {
                  background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
                  z-index: 1;
                  svg {
                    color: #ffffff;
                    * {
                      fill: #ffffff;
                    }
                  }
                }
              }
            }

            .arrow {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.7rem;
              height: 1.7rem;
              border-radius: 50%;
              cursor: pointer;
              background: #e2e5e9;
            }
          }

          &_large {
            width: 100%;
            height: 45px;
            border-radius: 4px;
            background: #f1f2f4;
            border: 1px solid rgba(109, 127, 146, 0.2);
            justify-content: space-between;
            align-items: center;
            flex-direction: initial;

            .action-side {
              .actions {
                .action {
                  &:nth-child(2) {
                    margin-left: -5px;
                  }
                }
              }
            }

            &_open {
              background: #dee2e6;
              .action-side {
                .arrow {
                  transform: rotate(180deg);
                  padding-top: 2px;
                  color: #29394d;
                  background: #ffffff;
                }
              }
            }
          }
          &_form {
            display: flex;
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            margin: 0px 5px 5px 0px;
            box-shadow: 0px 4px 10px 0px #29394d1a;
            border: 1px solid rgba(109, 127, 146, 0.2);
            .text-area {
              width: 50%;
              textarea {
                height: 80px;
                max-height: 150px;
                width: 100%;
                box-sizing: border-box;
                padding: 6px;
                box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
                border-radius: 5px;
                border: 1px solid #e1e4e8;
                background: #fff;
                color: #6d7f92;
                font-size: 12px;
                font-weight: 400;
              }
              .description_frame {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 6px;
                border-radius: 4px;
                border: 1px solid #f1f2f4;
                background: #fafbfb;
                color: #29394d;
                font-size: 12px;
                font-weight: 400;
              }
            }
            .attachment-list {
              margin-left: 10px;
              width: 50%;
              color: #29394d;
              font-weight: 400;
              font-size: 12px;
              .actions {
                display: flex;
                width: 14px;
                height: 14px;
                align-items: center;
                justify-content: space-between;
                > div {
                  height: 14px;
                  display: flex;
                  cursor: pointer;
                }
              }
              .action {
                padding: 6px;
                width: 30px;
                height: 30px;
                display: flex;
                background: transparent;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                border: 1px dotted #e1e4e8;
                cursor: pointer;
                &:focus {
                  box-shadow: none;
                  // box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #18a0fb, 0 1px 2px 0 rgb(0, 0, 0);
                }
              }
              .attachment-item {
                padding: 6px;
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                border: 1px dotted #e1e4e8;
                margin-bottom: 3px;
              }
            }
            .dropzone {
              width: fit-content;
            }
            &_sub {
              width: 96.5%;
              margin-left: 20px;
            }
          }
        }

        .small-grid {
          margin-left: 40px;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        .sub_card {
          width: 96.5%;
          background: #f8f9fa;
          margin-left: 20px;
          border: 1px solid rgba(109, 127, 146, 0.1);
          &_open {
            background: #e2e5e9;
            .action-side {
              .arrow {
                transform: rotate(180deg);
                padding-top: 2px;
                color: #29394d;
                background: #ffffff;
              }
            }
          }
        }
      }
      hr {
        border: 0.5px solid #d8dde2;
        margin: 17px 0px;
      }
    }
    .pannel {
      width: 254px;
      height: 521px;
      flex-shrink: 0;
      border-radius: 5px;
      border: 1px solid #e1e4e8;
      margin-left: 20px;
      padding: 10px 17px;
      position: relative;
      &_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        .action {
          color: #6d7f92;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 3px;
          cursor: pointer;

          svg {
            margin-left: 5px;
          }

          &_body {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            right: 14px;
            top: 32px;
            border: 1px solid #e1e4e8;
            border-radius: 5px;
            padding: 5px 0px;
            background: #ffffff;

            .option {
              height: 20px;
              padding: 0px 10px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              &:hover {
                background: #f3faff;
              }
            }
          }
        }

        label {
          color: #29394d;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .comments {
        max-height: 390px;
        overflow-y: scroll;
        .comment {
          border-radius: 5px;
          background: #f1f2f4;
          display: inline-flex;
          padding: 9px 10px;
          flex-direction: column;
          margin: 2px 0px;
          width: 100%;
          &_header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .actions {
              opacity: 0;
              transition: opacity 0.3s;
              display: flex;
              justify-content: space-between;
              height: 12px;
              div {
                width: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:first-child {
                  border-right: 1px solid rgba(225, 228, 232, 1);
                }
              }
            }
          }
          &_user {
            color: #29394d;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            &_details {
              display: flex;
              flex-direction: column;
              margin-left: 7px;
            }

            .avatar_not_exist {
              width: 28px;
              height: 28px;
              span {
                color: #fff;
              }
            }
            span {
              font-family: Roboto;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;

              &:first-child {
                font-weight: 600;
              }
            }
          }
          &_content {
            color: #6d7f92;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 5px;
          }
          &_footer {
            display: flex;
            justify-content: space-between;
            > div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: #6d7f92;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              .dot {
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background: #b2bcc6;
                margin: 0px 6px;
              }
              div {
                text-transform: uppercase;
              }
            }
          }
          &:hover {
            .actions {
              opacity: 1;
            }
          }
        }
      }

      .textarea {
        height: 80px;
        box-sizing: border-box;
        padding: 6px;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        margin-top: 2px;
        border-radius: 5px;
        border: 1px solid #e1e4e8;
        background: #fff;
        color: #6d7f92;
        font-size: 12px;
        font-weight: 400;
        &-form {
          border-radius: 5px;
          border: 1px solid #e1e4e8;
          box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
          background: #fff;
          padding: 10px;
          position: fixed;
          width: 17.6%;
          bottom: 18px;
          .set {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            span {
              color: #6d7f92;
              text-align: right;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .rectangular_select {
              margin-left: 5px;
              &_header {
                border: 0px;
                width: 99px;
                height: 20px;
                border-radius: 5px;
                background: #f1f2f4;
              }
              &_body {
                width: 99px;
                top: 1.3rem;
              }
            }
          }
          textarea {
            height: 150px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #f1f2f4;
            border-width: 1px 0px 0px 0px;
            padding: 6px;
            resize: none;
          }
          .actions {
            display: flex;
            justify-content: flex-end;
            .default {
              width: 28px;
              height: 28px;
              background: #f1f2f4;
              padding: 5px 0px;
              text-align: center;
              border-radius: 14px;
              text-align: center;
              margin-right: 5px;
              cursor: pointer;
            }
            .premium {
              height: 28px;
              width: 66px;
              background: #18a0fb;
              padding: 7px 0px;
              border-radius: 14px;
              color: #fff;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;
            }
          }
        }
      }
    }

    .matrix_frame {
      margin: 10px 0px 30px 0px;
      color: #6d7f92;
      font-size: 13px;
      width: 100%;
      line-height: normal;

      .CustumedMatrix_matrix__10BiN {
        background: #f8f9fa;
        margin-top: 5px;
      }
    }
    .CustumedMatrix_matrix__10BiN {
      width: 100%;
      .CustumedMatrix_matrix_body_line__11lbb {
        &:hover {
          background: #f1f2f4;
        }
        > div {
          height: 50px;
          .focus {
            input {
              border: 1px solid #18a0fb;
            }
            .action {
              color: #ffffff;
              background: #18a0fb;
            }
          }
          > div {
            height: 100%;
            justify-content: center;
            display: flex;
            align-items: center;

            input {
              background: #fff;
              border-radius: 4px;
              border: 0.5px solid #b2bcc6;
              height: 85%;
              cursor: text;
              width: 100%;
            }
          }

          &:first-child {
            width: 18% !important;
            min-width: 18% !important;
          }

          .actions {
            display: flex;
            .action {
              display: flex;
              justify-content: center;
              align-items: center;
              background-size: cover;
              vertical-align: middle;
              position: relative;
              width: 1.75rem;
              height: 1.75rem;
              overflow: hidden;
              border-radius: 50%;
              margin-left: -8px;
              cursor: pointer;
              background: #ffffff;
              color: #18a0fb;
              box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

              :global(.icon),
              svg {
                cursor: pointer;
                color: #18a0fb;
                font-weight: 500;
                font-size: 16px;
                margin: auto;
                width: 20px;

                * {
                  fill: #18a0fb;
                }

                &:global(.icon_setting) {
                  height: 17px;
                }
              }
              &:hover {
                background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
                bottom: 0.15rem;
                z-index: 1;
                svg {
                  color: #ffffff;

                  * {
                    fill: #ffffff;
                  }
                }
              }

              a {
                display: flex;
                flex: 1;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
              }
            }
            .disabled {
              opacity: 0.6;
              cursor: not-allowed;

              &:hover {
                background: #ffffff;
                bottom: 0;
                z-index: 1;
                svg {
                  color: #18a0fb;

                  * {
                    fill: #18a0fb;
                  }
                }
              }
            }

            .active {
              opacity: 1;
              cursor: pointer;
              background: #18a0fb;
              svg {
                color: #ffffff;

                * {
                  fill: #ffffff;
                }
              }

              &:hover {
                background: #18a0fb;
                bottom: 0;
                z-index: 1;
                svg {
                  color: #ffffff;

                  * {
                    fill: #ffffff;
                  }
                }
              }
            }

            & div div {
              font-size: 0.7rem;
              font-weight: 400;
            }
          }
        }

        &:first-child {
          border-bottom: 1px solid #d8dde2 !important;
        }

        .p-radiobutton-checked {
          .p-radiobutton-box {
            border-color: #ced4da;
            background: #fff;
            .p-radiobutton-icon {
              background-color: #18a0fb;
            }
          }
        }
      }
    }

    .second_matrix {
      .CustumedMatrix_matrix__10BiN {
        .CustumedMatrix_matrix_body_line__11lbb {
          div {
            &:first-child {
              .matrix_title {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  .vertical_content {
    flex-direction: column;
  }
}
.expanded_line {
  background: #f1f2f4 !important;
}
.sub_line {
  height: 60px;
  border-bottom: 0px;
  &:hover {
    background: #fff !important;
  }
}
.last_sub_line {
  border-bottom: 1px solid #d8dde7 !important;
  height: 70px;
}

.rectangular_select {
  align-items: center;
  display: flex;
  margin: 5px 0px;
  position: relative;
  &_header {
    align-items: center;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    color: #6d7f92;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    height: 28px;
    justify-content: space-between;
    padding: 0 0.813rem 0 0.75rem;
    width: 179px;

    div {
      color: #29394d;
    }

    svg {
      transform: rotate(0deg);
      transition: transform 0.7s;
      color: #6d7f92 !important;
    }
  }
  &_body {
    background: #fff;
    border: 1px solid #b2bcc6;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    display: flex;
    flex-direction: column;
    left: 0;
    margin: 0;
    max-height: 14rem;
    position: absolute;
    top: 1.8rem;
    width: 179px;
    z-index: 10;
    &_options {
      overflow: scroll;
    }
    input {
      background: #f8f9fa;
      border: 0.5px solid #b2bcc6;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding: 9px 15px;
      width: 100%;
    }
    &_option {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 0.75rem;
      font-weight: 400;
      padding: 3px 10px;
      color: #29394d;

      .avatar {
        margin-right: 8px;
      }

      &:hover {
        background: #f3faff;
      }
    }
    .input_form {
      position: relative;
      .action-side {
        visibility: hidden;
        position: absolute;
        right: 10px;
        top: 5px;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        transform: rotate(180deg);
        height: 20px;
        width: 20px;
        background: #18a0fb;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        .arrow {
          display: flex;
          justify-content: center;
        }
      }
      .disabled {
        opacity: 0.3;
        cursor: no-drop;
      }
      &:hover {
        .action-side {
          visibility: visible;
        }
      }
    }
  }
  &_opened {
    svg {
      transform: rotate(180deg);
      margin-bottom: 1px;
    }
  }
  .p-inputtext {
    font-size: 12px;
  }
}

.TODO_step {
  background: #6d7f921a;
  color: #29394d;
}
.WIP_step {
  background: #ea81241a;
  color: #d87318;
}
.VALIDATION_step {
  background: #18a0fb1a;
  color: #3b8ad5;
}
.DONE_step {
  background: #06d9b11a;
  color: #02af8e;
}

.TODO_dot {
  background: #b2bcc6 !important;
}
.WIP_dot {
  background: #ea8124 !important;
}
.VALIDATION_dot {
  background: #18a0fb !important;
}
.DONE_dot {
  background: #06d9b1 !important;
}

.HIGHT_priority {
  color: #de4848;
}
.MEDIUM_priority {
  color: #d87318;
}
.LOW_priority {
  color: #29394d;
}

.p-datepicker {
  width: 264px !important;
  .p-datepicker-header {
    padding: 0px 0.5rem !important;
    .p-datepicker-title .p-datepicker-month,
    .p-datepicker-title .p-datepicker-year {
      padding: 0.25rem !important;
    }
  }
  table {
    margin: 0px !important;
    thead {
      font-size: 14px !important;
    }
    th {
      padding: 6px !important;
    }
    td > span {
      width: 20px !important;
      font-size: 12px !important;
      height: 20px !important;
    }
  }
}

.large_calendar {
  width: 290px !important;
  left: 1098.37px !important;
  table {
    td > span {
      width: 23px;
    }
  }
}

.button_actions {
  display: flex;
  padding-right: 20px;
  .save {
    margin-left: auto;
    background: rgb(6, 217, 177) !important;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    .disabled {
      opacity: 0.4;
      cursor: not-allowed !important;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 38px 0px 6px;
  padding: 10px;
  border-top: 1px solid #e1e4e8;
  position: fixed;
  bottom: 0;
  left: 225px;
  background: #fff;

  > div,
  > div > div {
    height: 40px;
    padding: 12px 26px 12px 26px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
    cursor: pointer;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
  .action {
    &_left {
      color: #6d7f92;
      border: 1px solid #d8dde2;
      background: #ffffff;
    }
    &_right {
      margin-left: 5px;
      color: #ffffff;
      background: #06d9b1;
    }
  }
}

.tab_badge {
  min-width: 170px;
  margin-left: 3px;
  text-align: center;
  padding: 0px 0px 2px 0px;
  border-bottom: 3px solid;
  border-color: #b2bcc6;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #6d7f92;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  text-transform: lowercase;

  &_active {
    border-color: #18a0fb;
    color: #18a0fb;
    span {
      background: #18a0fb !important;
    }
  }

  &_disabled {
    opacity: 0.7;
    cursor: no-drop;
  }
  span {
    color: #f1f2f4;
    background: #6d7f92;
    height: 18px;
    padding: 2px 4px;
    border-radius: 3px;
    margin: 2px 0px;
  }
}
.pre-form {
  width: 100%;
  .p-carousel {
    width: 95%;
  }
  .p-carousel .p-carousel-indicators {
    padding: 7px;
    display: none;
  }

  .p-checkbox {
    margin-right: 10px;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #6d7f92;
    background: #6d7f92;
    color: #ffffff;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem rgb(109, 127, 146, 0.2);
    border-color: #6d7f92;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #6d7f92;
  }
  .row {
    display: flex;
    .p-checkbox {
      width: 26px;
      height: 26px;
      .p-checkbox-box {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.modal-form {
  .instruction {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #6d7f92;
    margin: 8px 5px 3px 0px;
  }
  .form_card {
    border: 0.8px solid #b2bcc6;
    border-radius: 5px;
    color: #29394d;
    height: 30px;
    width: 351px;
    background: #f8f9fa;
    padding: 8px;
    font-weight: 400;
    line-height: 17px;
    font-size: 12px;
    padding: 0px 10px;

    input {
      border: none;
      height: 100%;
      cursor: text;
      width: 95%;
      background: #f8f9fa;
    }
    .action-side {
      .arrow {
        background: #18a0fb;
        * {
          fill: #ffffff;
        }
      }
    }
    &:hover {
      background: #f8f9fa;
    }
  }
  .p-checkbox {
    margin-right: 10px;
    margin-top: 2px;
    width: 26px;
    height: 26px;
    .p-checkbox-box {
      width: 26px;
      height: 26px;
    }
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #6d7f92;
    background: #6d7f92;
    color: #ffffff;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem rgb(109, 127, 146, 0.2);
    border-color: #6d7f92;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #6d7f92;
  }
  &_footer {
    width: 91%;
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    margin: 35px 0px 0px;
    padding: 10px;
    background: #fff;

    > div {
      height: 35px;
      padding: 12px 26px 12px 26px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 19px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .action_left {
      color: #6d7f92;
      border: 1px solid #d8dde2;
      background: #ffffff;
    }
    .action_right {
      color: #ffffff;
      background: #6d7f92;
    }
  }
}

.action-bar {
  height: 34px;
  width: 34px;
  border-radius: 5px;
  background: #f1f2f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  cursor: pointer;
}

.rectangular_select_large {
  .rectangular_select_header {
    width: 390px;
    height: 34px;
  }
  .rectangular_select_body {
    & > input {
      width: 388px !important;
      min-height: 30px !important;
      margin: 1px 0px 0px !important;
      border-radius: 4px !important;
    }
    width: 390px;
  }
}

.ant-table table {
  border-collapse: separate !important;
  margin-bottom: 0 !important;
}

.custom-column-header {
  margin-bottom: 0;
  background-color: #29394d;
  color: #FFF;
  font-size: 14px;
  border-radius: 5px;
  padding: 7px 15px;
  width: 345px !important;
}

.custom-column-header-v2 {
  margin-bottom: 0;
  background-color: #29394d;
  color: #FFF;
  font-size: 14px;
  padding: 7px 15px;
  display: block;
  width: 100%;
  border-radius: 5px;
  text-align: center !important;
  border: 2px solid white;
}

.custom-cell {
  background-color: #e5e8eb;
  padding: 0 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 345px !important;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
  height: 40px;
  border: none !important;
}

.ant-table-row-level-0, .ant-table-row-level-1, .ant-table-row-level-2, .ant-table-row-level-3 {
  text-align: center !important;
  background-color: transparent !important;
  margin-top: 5px !important;

  .ant-table-cell {
    border: 2px solid white;
    border-radius: 5px;
  }
}

.ant-table-row-expand-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  width: 16px;
  height: 16px;
  color: #6d7f92;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  margin: 0 !important;
}

.antd-table-cell {
  text-align: center !important;
}

/* Styles for expanded state */
.ant-table-row-expand-icon-expanded {
  transform: rotate(180deg); /* Rotate icon when expanded */
  background: transparent; /* Remove background for consistency */
  top: -24px;
  margin: 0 !important;
}

/* Styles for collapsed state */
.ant-table-row-expand-icon-collapsed {
  color: #6d7f92; /* Default icon color */
  background: transparent; /* Remove background for consistency */
  top: -24px;
  margin: 0 !important;
}

.ant-table-row-level-1 {
  .ant-table-row-expand-icon-collapsed, .ant-table-row-expand-icon-expanded {
    top: -2px;
  }
}

.ant-table-tbody > .ant-table-row > td {
  background-color: #e5e8eb;
  
}

.ant-table-row:last-child > td {
  border-radius: 5px;
}

.custom-cell-spacing {
  border-radius: 5px;
}

.custom-cell-spacing:last-child {
  border-right: none;
  text-align: left;
}

.no-value-cell {
  width: 100%;
  height: 40px;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.no-value-cell::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 3px;
  background-color: white;
  transform-origin: center;
  transform: translate(-50%);
  z-index: 5;
}