.content {
  display: flex;
}

.aside-colomn{
  width: 21%;
  .card-frame {
    .logo {
      z-index: 2;
      overflow: hidden;
      width: 160px;
      height: 90px;
      border: 1px solid #D8DDE2;
      border-radius: 5px;
    }
    .card-frame {
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 20px 20px;
      margin: 0px 20px 0px 0px;
      border-radius: 5px;
      background: #FFFFFF;
      border: 1px solid #F1F2F4;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      line-height: 160%;

      h5 {
        margin-top: 16px;
        font-size: 16px;
        color: #29394D;
      }
      label {
        font-size: 12px;
        color: #6D7F92;
      }
      .infos-icons {
        // width: 40%;
        display: flex;
        margin: 10px 0px;
        justify-content: space-around;
        gap: 12px;
        &_icon {
          width: 32px;
          height: 32px;
          background: #F1F2F4;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: fit-content;
          }
        }
      }
      .list {
        width: 100%;
        margin-bottom: 10px;
        &_header {
          display: flex;
          align-items: center;
          width: 100%;
          height: 22px;
          padding: 3px 0px ;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          text-align: center;
          border-bottom: 1px solid #E1E4E8;

          > div {
            width: 83%;
            display: flex;
            height: 13px;
            justify-content: space-between;
            align-items: center ;
            padding: 0 5px;
            border-right: 1px solid #E1E4E8;
          }
          svg {
            &:first-child {
              margin: 0px 5px 0px 0px;
            }
          }
          span > svg {
            margin: 0px 5px 0px 5px!important;
            cursor: pointer;
          }
        }
        &_body {
          font-size: 13px;
          margin: 5px 0px 0px 30px;
          ul {
            list-style: square;
            text-align: start;
          }
        }
      }
      a{
        width: 100%;
      }

      .tag_pie {
        width: 194px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 200px;
        height: 44px;
        padding: 6px 12px;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        border-radius: 5px;

        .tag {
          height: 100%;
          margin: 0 auto;
          padding: 1px 10px;
          width: 100%;
          border-radius: 5px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          display: flex;
          align-items: center;

          svg {
            margin: 0px 5px;
          }
        }
        .tag-success {
          background: rgba(6, 217, 177, 0.1);
          color: #02AF8E;
        }
        .tag-danger {
          background: rgba(235, 87, 87, 0.1);
          color: #EB5757;
        }
        .pie-frame {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0 auto;
          color: #EB5757;
          top: -54px;
          position: relative;

          @property --p{
            syntax: '<number>';
            inherits: true;
            initial-value: 0;
          }

          .pie {
            --p:20;
            --b:2.5px;
            --c:#EB5757;
            --w:150px;

            width:40px;
            aspect-ratio:1;
            position:relative;
            display:inline-grid;
            margin:5px;
            place-content:center;
            font-family: 'Roboto';
            font-weight:700;
            font-style: normal;
            font-size: 12px;
          }
          .pie:before,
          .pie:after {
            content:"";
            position:absolute;
            border-radius:50%;
          }
          .pie:before {
            inset:0;
            background:
              radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
              conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
            -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
                    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          }
          .pie:after {
            inset:calc(50% - var(--b)/2);
            background:var(--c);
            transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(760% - (var(--w) /2) / 2));
          }
          .animate {
            animation:p 1s .5s both;
          }
          .no-round:before {
            background-size:0 0,auto;
          }
          .no-round:after {
            content:none;
          }
          @keyframes p {
            from{--p:0}
          }
        }
        a{
          height: 0px;
          overflow: hidden;
          transition: height 0.2s linear;
        }
        .action {
          display: flex;
          padding: 10px;
          width: 100%;
          height: 100%;
          overflow: hidden;
          align-items: center;
          border: 1px solid #D8DDE2;
          border-radius: 50px;
          // margin-top: 20px;
          cursor: pointer;
          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #6D7F92;
          }
          svg {
            margin: 0px 10px;
          }
        }
        &:hover {
          .pie-frame {
            display: none;
          }
          .tag {
            display: none;
          }
          a {
            height: 44px;
          }
        }
      }
    }

    .card-fetching {
      .card-frame {
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        margin: 0px 20px 0px 0px;
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;

        .logo {
          z-index: 2;
          overflow: hidden;
          width: 160px;
          height: 90px;
          border: 1px solid #D8DDE2;
          border-radius: 5px;
        }

        > div {
          &:nth-child(2) {
            margin: 16px 0px 0px;
            width: 190px;
            height: 14px;
          }
          &:nth-child(3) {
            margin-top: 8px;
            width: 90px;
            height: 14px;
          }
        }

        .infos-icons {
          // width: 40%;
          display: flex;
          margin: 10px 0px;
          justify-content: space-around;
          .icon {
            width: 32px;
            height: 32px;
            background: #F1F2F4;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .list {
          width: 100%;
          margin-bottom: 10px;
          &_header {
            display: flex;
            align-items: center;
            width: 100%;
            height: 22px;
            border-bottom: 1px solid #E1E4E8;

            > div {
              &:first-child {
                margin: 0px 100px 0px 0px;
                width: 72px;
                height: 14px;
              }

              &:nth-child(2), &:nth-child(3) {
                margin: 0px 5px 0px 0px;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
  .history-frame{
    background: #FFFFFF;
    margin: 20px 20px 0px 0px;
    padding: 15px 17px;
    border: 1px solid #F1F2F4;
    border-radius: 5px;

    h3 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #29394D;
    }

    .customed_select {
      background: rgb(241, 242, 244);
      width: 100%;
      margin: 20px 0px;
      position: relative;
      z-index: 3;

      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
      text-align: left;
      text-transform: none;
    }

    .cellules {
      height: 540px;
      overflow: scroll;

      .no_data {
        text-align: center;
        margin: 4rem 0px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 250px;
        font-family: Roboto;
        .subTitle {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;

        }
      }

      .cellule {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
        padding-bottom: 7px;
        border-bottom: 0.5px solid rgba(109, 127, 146, 0.1);

        &_header {
          display: flex;
          align-items: center;
          margin: 15px 0px 0px;

          .avatar {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 50%;
            border: 2px solid #E1E4E8;
            background-size: cover;
            background-position: top center;
          }

          .infos {
            display: flex;
            flex-direction: column;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;

            span {
              &:first-child {
                font-size: 13px;
                line-height: 14px;
                color: #29394D;
              }

              &:nth-child(2) {
                font-size: 11px;
                line-height: 12px;
                color: #6D7F92;
              }
            }
          }
        }

        .title {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #29394D;
          margin: 10px 0px;
        }

        .type {
          font-weight: 400;
          font-size: 13px;
          line-height: 14px;
          text-transform: uppercase;
          color: #29394D;
        }

        .line {
          margin: 5px 0px;

          .label {
            color: #6d7f92;
          }

          .value {
            color: #29394d;
          }
        }

        &_fetching {
          border-bottom: 0.5px solid rgba(109, 127, 146, 0.1);
          padding-bottom: 10px;
          .header {
            display: flex;
            align-items: center;
            margin: 15px 0px 0px;

            .avatar {
              width: 35px;
              height: 35px;
              margin-right: 10px;
              border-radius: 50%;
              border: 2px solid #E1E4E8;
              background-size: cover;
              background-position: top center;
            }

            .infos {
              display: flex;
              flex-direction: column;

              span {
                &:first-child {
                  height: 13px;
                  width: 100px;
                  margin-bottom: 3px;
                }

                &:nth-child(2) {
                  height: 10px;
                  width: 100px;
                }
              }

            }
          }

          .title {
            margin: 10px 0px;
            height: 14px;
            width: 80%;
            margin-bottom: 4px;
          }

          .type {
            height: 14px;
            width: 80%;
            margin-bottom: 4px;
          }

          .line {
            margin: 5px 0px;
            display: flex;

            .label {
              height: 14px;
              width: 42%;
              margin-right: 4px;
            }

            .value {
              height: 14px;
              width: 42%;
            }
          }
        }
      }


    }
  }
}
.large-colomn {
  width: 76%;

  .upper-frame {
    display: flex;
    .left-panel {
      width: 79%;
      margin-right: 20px;
      .tag-frame {
        display: flex;
        justify-content: space-between;
        .tag_progress {
          width: 239px;
          height: 100px;
          padding: 10px 18px;
          background: #FFFFFF;
          border: 1px solid #F1F2F4;
          border-radius: 5px;

          &_info {
            display: flex;
            justify-content: space-between;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 160%;
            color: #6D7F92;
          }

          &_container {
            width: 100%;
            height: 4px;
            border-radius: 3px;
            background: rgba(109, 127, 146, 0.3);
          }
          &_bar {
            height: 4px;
            background: #29394d;
            border-radius: 3px;

          }
          &_index {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Roboto';
            flex-direction: column;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            svg {
              margin: 4px 0 0 0;
              transform: rotate(180deg);
            }
          }
        }
        .bg-success {
          .item_progress_bar {
            background: #06D9B1;
          }
          color: #02AF8E;
        }
        .bg-danger {
          .item_progress_bar {
            background: #EB5757;
          }
          color: #DE4848;
        }
        .bg-warning {
          .item_progress_bar {
            background: #EA8124;
          }
          color: #EA8124;
        }

      }
      .list-frame {
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        border-radius: 5px;
        margin-top: 20px;
        padding: 21px 18px 20px;
        min-height: 478px;

        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 40px;
          &_title {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            color: #29394D;
          }
          &_tabs {
            display: flex;
            .tab_badge {
              width: 136px;
              height: 24px;
              margin-left: 3px;
              text-align: center;
              padding: 0px 0px 7px 0px;
              border-bottom: 3px solid;
              border-color: #b2bcc6;
              cursor: pointer;

              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              color: #6d7f92;

              &_active {
                border-color: #18A0FB;
                color: #18A0FB;
              }
            }
          }
        }

        &_body {
          .slide-content {
            width: 100%;
            display: flex;
            .slide-matrix {
              width: 650px;

              .tag-container {
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                .tag {
                  width: fit-content!important;
                }
                .tag-success {
                  div {
                    color: #02AF8E;
                    background: rgba(6, 217, 177, 0.10);
                  }
                }
              }
            }
            .slide-column {
              width: 72px;
              display: flex;
              justify-content: flex-end;
              .actions {
                display: flex;
                margin-top: 20px;
                div {
                  width: 24px;
                  height: 24px;
                  border-radius: 5px;
                  border: 1px solid var(--Dark-1, #29394D);
                  margin-left: 5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                // .next {
                //   transform: rotate(180deg);
                // }
              }
            }
          }
          max-height: 600px;
          overflow: scroll;
          .list-frame_item {
            display: block;
            opacity: 1;
            width: 722px;
            &_header {
              height: 60px;
              display: flex;
              padding: 10px;
              position: relative;
              justify-content: space-between;
              border-bottom: 1px solid #F1F2F4;
              .title {
                width: 50%;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: #29394D;
              }

              .total {
                position: absolute;
                right: 254px;
                top: 16px;
                overflow: hidden;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                padding-right: 15px;
                line-height: 14px;
              }

              .progress {
                width: 15%;
                padding: 0px 10px;
                border-right: 1px solid #E1E4E8;

                font-family: 'Roboto';
                flex-direction: column;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;


                &_container {
                  width: 100%;
                  height: 28px;
                  border-radius: 3px;
                  background: #E1E4E8;
                }
                &_bar {
                  height: 28px;
                  background: #06D9B1;
                  border-radius: 3px;
                }
                &_index {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 2px;
                }
              }

              .avatars {
                padding: 0px 20px;
                text-align: center;
                align-items: center;
                justify-content: flex-start;
                display: flex;
                width: 33%;

                .avatar {
                  position: relative;
                  &:nth-child(2) {
                    z-index: 2;
                    right: 20px;
                  }
                  &:nth-child(3) {
                    z-index: 3;
                    right: 40px;
                  }
                  &:nth-child(4) {
                    z-index: 4;
                    right: 60px;
                  }
                  &:nth-child(5) {
                    z-index: 5;
                    right: 80px;
                  }
                  &_exist {
                    width: 35px;
                    height: 35px;
                    margin-right: 10px;
                    border-radius: 50%;
                    border: 2px solid #E1E4E8;
                    background-size: cover;
                    background-position: top center;
                  }

                  &_not_exist {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 34px;
                    height: 34px;
                    font-size: 12px;
                    font-weight: 600;
                    margin: auto;
                    color: white;
                    border: 1px white solid;
                    border-radius: 50%;
                    background-color: darkgray;
                    cursor: default;

                    & > span {
                      margin: auto;
                    }
                  }
                }
              }

              .action {
                width: 1.7%;
                display: flex;
                align-items: center;
              }

              &:hover {
                background: #F1F2F4;
                cursor: pointer;

                .action {
                  * {
                    fill: #18A0FB
                  }
                }
              }
            }

            &_body {
              display: none;
              padding: 10px 20px;

              .header {
                height: 80px;
                display: flex;
                padding-bottom: 10px;
                border-bottom: 1px solid #29394D;
                label {
                  width: 55%;
                  display: flex;
                  align-items: flex-end;
                  padding-bottom: 5px;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  color: #29394D;
                }
              }
              .line {
                display: flex;
                height: 44px;
                padding: 7px 0px;
                .infos {
                  min-width: 55%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  color: #29394D;

                  .avatar {
                    width: 28px;
                    height: 28px;
                    margin-right: 10px;
                    border-radius: 50%;
                    border: 2px solid #E1E4E8;
                    background-size: cover;
                    background-position: top center;
                  }

                  div {
                    &:nth-child(2) {
                      width: 55%;
                    }
                    &:nth-child(3) {
                      min-width: 132px;
                      text-align: start;
                    }
                  }
                }
              }
            }

            &__open {
              .list-frame_item_header {
                background: #F1F2F4;
                .action {
                  > svg {
                    transform: rotate(180deg);
                    transition: transform 0.3s ease-out;
                  }
                }
              }
              .list-frame_item_body {
                background: #F8F9FA;
                display: block;
                animation: growDown 300ms ease-in-out forwards;
                transform-origin: top center;
              }
            }
          }
        }

        .list_fetching {
          .list-frame_item {
            width: 722px;
            &_header {
              height: 60px;
              display: flex;
              padding: 10px;
              justify-content: space-between;
              border-bottom: 1px solid #F1F2F4;

              .title {
                width: 50%;
                width: 30%;
                margin-right: 20%;
                margin-top: 3px;
                height: 24px;
                color: #29394D;
              }

              .progress {
                width: 15%;
                padding: 0px 10px;
                border-right: 1px solid #E1E4E8;

                &_container {
                  width: 100%;
                  height: 28px;
                  border-radius: 3px;
                  background: #E1E4E8;
                  margin-top: 3px;
                }
                &_bar {
                  height: 28px;
                  border-radius: 3px;
                }
                &_index {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 2px;
                }
              }

              .avatars {
                padding: 0px 20px;
                text-align: center;
                align-items: center;
                justify-content: flex-start;
                display: flex;
                width: 33%;

                .avatar {
                  position: relative;
                  &:nth-child(2) {
                    z-index: 2;
                    right: 20px;
                  }
                  &:nth-child(3) {
                    z-index: 3;
                    right: 40px;
                  }
                  &:nth-child(4) {
                    z-index: 4;
                    right: 60px;
                  }
                  &:nth-child(5) {
                    z-index: 5;
                    right: 80px;
                  }

                  &_not_exist {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 34px;
                    height: 34px;
                    font-size: 12px;
                    font-weight: 600;
                    margin: auto;
                    color: white;
                    border: 1px white solid;
                    border-radius: 50%;
                    background-color: darkgray;
                    cursor: default;

                    & > span {
                      margin: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .right-panel {
      width: 19%;
      padding: 10px 20px;
      border-left: 0.5px solid #E1E4E8;
      .unity {
        padding: 11px 0px;
        font-family: 'Roboto';
        font-style: normal;
        display: flex;
        flex-direction: column;

        label {
          font-weight: 400;
          line-height: 140%;
          font-size: 12px;
          color: #6D7F92;
          margin-bottom: 3px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          text-transform: capitalize;
          color: #29394D;

        }
      }
    }

  }
  .lower-frame {
    margin-top: 20px;
    padding: 21px 18px 20px;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    border-radius: 5px;
    display: flex;

    .table-panel {
      width: 70%;

      &_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #29394D;
      }
      &_table {
        margin-top: 15px;
        .header {
          height: 70px;
          display: flex;
          padding: 0px;
          padding-bottom: 10px;
          border-bottom: 1px solid #29394D;
          label {
            width: 55%;
            display: flex;
            align-items: flex-end;
            padding-bottom: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #29394D;
          }
        }
        .line {
          display: flex;
          height: 44px;

          .infos {
            width: 55%;
            display: flex;
            align-items: center;

            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #29394D;
          }
        }
      }
      &_fetching {
        .header {
          height: 70px;
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid #29394D;
          label {
            width: 45%;
            height: 20px;
            display: flex;
            align-items: flex-end;
            margin: 15px 64px 5px 0px;
            border-radius: 5px;
          }
        }
        .line {
          display: flex;
          height: 44px;

          .infos {
            width: 45%;
            height: 24px;
            display: flex;
            align-items: center;
            margin: 15px 64px 5px 0px;
            border-radius: 5px;
          }
        }
      }
    }

    .chart-panel {
      padding: 20px 40px;
      &_unity {
        margin-bottom: 30px;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #29394D;
          margin-bottom: 10px;
        }
        .frame {
          border: 1px solid #E1E4E8;
          border-radius: 5px;
          height: 130px;
          display: flex;

          .label {
            width: 50%;
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            text-align: right;
            text-transform: capitalize;
            color: #29394D;

            span {
              &:nth-child(3) {
                font-size: 12px;
                line-height: 14px;

              }
            }
          }
          .value_tag {
            position: relative;
            top: 55px;
            left: 83px;
            font-size: 13px;
            color: #242731;
          }
          .pie_frame {
            width: 50%;
            text-align: center;
            padding-top: 13px;
            .pie {
              --w:100px;
              width: var(--w);
              aspect-ratio: 1;
              position: relative;
              display: inline-grid;
              place-content: center;
              margin: 5px;
              font-size: 25px;
              font-weight: bold;
              font-family: sans-serif;
            }
            .pie:before {
              content: "";
              position: absolute;
              border-radius: 50%;
              inset: 0;
              background: conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
              -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
                      mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
            }
          }
        }
      }
    }
  }

  .tag {
    width: 15%;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
    }
    > div {
      height: 28px;
      width: 73px;
      background: #F1F2F4;
      border-radius: 5px;
      margin-top: 10px;

      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      padding-right: 5px;
      justify-content: flex-end;
      color: #6D7F92;
    }
  }

  .tag-danger {
    > div {
      background: rgba(235, 87, 87, 0.1);
      color: #DE4848;
    }
  }

  .tag-warning {
    > div {
      background: rgba(234, 129, 36, 0.1);
      color: #EA8124;
    }
  }

  .tag-success {
    > div {
      background: rgba(6, 217, 177, 0.1);
      color: #02AF8E;
    }
  }
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}

.cl-danger {
  color: #DE4848;
}

.cl-warning {
  color: #EA8124;
}

.cl-success {
  color: #02AF8E;
}

.progress {
  width: 100%;
  padding: 5px 10px;

  font-family: 'Roboto';
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;


  &_container {
    width: 100%;
    height: 28px;
    border-radius: 3px;
    background: #E1E4E8;
    position: relative;
  }
  &_bar {
    height: 28px;
    background: #06D9B1;
    border-radius: 3px;
  }
  &_total {
    position: absolute;
    top: 9px;
    right: 2px;
    color: #6D7F92;
  }
  &_index {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
  }
}

.avatar {

  &_exist {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #E1E4E8;
    background-size: cover;
    background-position: top center;
  }

  &_not_exist {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-weight: 600;
    margin: auto;
    color: white;
    border: 1px white solid;
    border-radius: 50%;
    background-color: darkgray;
    cursor: default;

    & > span {
      margin: auto;
    }
  }
}

.tags_fetching {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .progress {
    width: 200px;
    padding: 0px 18px;
    padding-top: 7px;
    border-right: none;
    &_info {
      display: flex;
      justify-content: space-between;
      height: 18px;

      > div {
        width: 30px;
        border-radius: 5px;

        &:first-child {
          width: 90px;

        }
      }
    }

    &_container {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: rgb(238, 238, 238);
    }

    &_icon {
      width: 12px;
      height: 12px;
      margin-top: 7px;
      border-radius: 3px;
    }
    &_index {
      margin-top: 3px;
      width: 20px;
      height: 12px;
    }
  }

  .tag_pie {
    .tag {
      height: 20px;
      margin: 0 auto;
      width: 146px;
      border-radius: 5px;
      width: 146px!important;
    }
  }
}

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}
