
.tab-container {
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 40px;

    .p-carousel {
        width: 97%;
    }
}

.carousel-item{
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96%;
    height: 52px;
    font-family: Roboto, "Segoe UI", Helvetica, sans-serif;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #18a0fb;
}
.active {
    color: #ffffff;
    background: #18a0fb;
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    width: 52px;
    background: transparent!important;
    border-radius: 0px!important;
    &:focus {
        box-shadow: 0 0 0 0 !important;
    }

}

