$page-size: 36px;
.pagination {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 30px auto!important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .1rem;
    &:last-child, &:first-child {
      display: flex;
    }
  }
  .current{
    padding: 0;
  }
  a:hover {
    background: transparent;
  }
  .next, .previous {
    text-transform: uppercase;
    border: 1px solid #E1E4E8;
    background:  #FFF;
    border-radius: 40px;
    width: 130px;
    height: $page-size;
    a {
      color: #2495E1;
    }
    &:hover {
      background: #2495E1;
      a {
        color: #fff;
      }
    }
  }
  .disabled a {
    color: #6D7F92;
  }
  .page, .break {
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #E1E4E8;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 14px;
    color: #3C4E64;
    width: $page-size;
    height: $page-size;
    padding: 0;
    a {
      color: #3C4E64;
    }
    &:hover {
      background: #2495E1;
      a {
        color: #fff;
      }
    }
  }
  .current {
    background: #2495E1;
    a {
      color: #fff;
    }
  }
  .disabled {
    padding: 0;
    background:  #FFF!important;
    a {
      color: #6D7F92!important;
      cursor: not-allowed !important;
    }
  }
  .pagination li:last-child, .pagination li:first-child {
    display: flex;
  }
}
.dark_pagination {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 14px auto;
  line-height: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .1rem;
    &:last-child, &:first-child {
      display: flex;
    }
  }
  .current{
    padding: 0;
  }
  a:hover {
    background: transparent;
  }
  .next, .previous {
    text-transform: uppercase;
    background: #18A0FB;
    border-radius: 40px;
    width: 130px;
    margin: 0px 10px;
    height: $page-size;
    a {
      color: #F8F9FA;
      font-weight: 500;
    }
  }
  .disabled{
    background: #3C4E64;
    a {
    color: rgba(147, 158, 173, 1);
    }
  }
  .page, .break {
    text-align: center;
    background: #3C4E64;
    border: 1px solid #3C4E64;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 14px;
    color: #F1F2F4;
    width: $page-size;
    height: $page-size;
    padding: 0;
    a {
      color: #ffffff;
    }
    &:hover {
      background: #2495E1;
      a {
        color: #fff;
      }
    }
  }
  .current {
    background: #2495E1;
    a {
      color: #fff;
    }
  }
  .disabled {
    padding: 0;
    a {
      cursor: not-allowed !important;
    }
  }
  .pagination li:last-child, .pagination li:first-child {
    display: flex;
  }
}
