.result_label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #29394D;
  display: flex;
  align-items: center;
  span {
    font-weight: 500;
  }
  .action {
    align-items: center;
    border: 1px solid #f1f2f4;
    border: 1px solid var(--gris-3, #f1f2f4);
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 25px;
    justify-content: center;
    margin-right: 5px;
    width: 30px;
  }
}
.financial_table {
  .table_header {
    display: flex;
    align-items: center;

    div {
      display: flex;
      text-align: center;
      background-color: #3C4E64;
      padding: 7px;
      display: inline-block;
      width: 100%;
      margin-top: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      text-transform: uppercase;
      color: white;
      text-align: center;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px 
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .table_body {
    .cellule {
      height: 100%;
      color: #29394d;
      text-align: center;
      background-color: #f1f2f4;
      padding: 7px;
      display: inline-block;
      width: 100%;
      margin-top: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        justify-content: start;
        padding-left: 20px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .composed_cellule {
        width: 100%;
      > div {
        display: flex;
        justify-content: center;
        &:last-child {
          display: flex;
          font-size: 11px;
          justify-content: center;
          div {
            width: 50%;
            display: flex;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
    
    .total_line {
      .cellule {
        background-color: rgb(200, 205, 210);
      }
    }
    .line {
      height: 70px;
      display: flex;
      margin-top: 3px;
    }
  }
}