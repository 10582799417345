.modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 57, 77, 0.6);
  z-index: 1099999;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  overflow: none;
  outline: none;
  padding: 0;
  transition: right 300ms ease-in-out;
}

.modalContentAfterOpen {
  right: 0;
}

.modalContentBeforeClose {
  right: -100%;
}

.close {
  position: absolute;
    right: -1px;
    top: -1px;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    display: flex;
    font-size: 23px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #f1f2f4;
    border: 1px solid rgb(204, 204, 204);
}