.not_authorized__container {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: flex;

    .not_authorized__block_icon {
        font-size: 2em;
    }

    .not_authorized__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: auto;
        gap: 30px;
        width: calc(100% - 10px);
        height: calc(100vh - 10px);
        background-color: rgba(#c7e8fe, .9);
        padding: 100px;

        h1 {
            font-size: 4em;
        }
        span {
            font-size: 1.5em;
        }
    }
}