.line_fetching_reporting {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 5px;
  margin-bottom: 10px;
  height:  77px;
  padding: 16px 26px;

  .infos {
    width: 25%;
    height: 100%;
    border-right: 1px solid #E1E4E8;
    padding-top: 5px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;

    &_header {
      width: 190px;
      height: 19px;
      margin-bottom: 7px;
      border-radius: 5px;
    }
  
    &_sub_header {
      width: 130px;
      height: 16px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
  }

  .state {
    width: 11%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #E1E4E8;

    &_icon {
      height: 12px;
      width: 12px;
      margin-bottom: 5px;
      border-radius: 3px;
    }
    &_label {
      width: 65%;
      height: 14px;
      border-radius: 3px;
    }
  }

  .progress {
    width: 200px;
    padding: 0px 18px;
    padding-top: 7px;
    border-right: 1px solid #E1E4E8;
    &_info {
      display: flex;
      justify-content: space-between;
      height: 18px;

      > div {
        width: 90px;
        border-radius: 5px;

        &:first-child {
          width: 30px;

        }
      }
    }

    &_container {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: rgb(238, 238, 238);
    }

    &_icon {
      width: 12px;
      height: 12px;
      margin-top: 7px;
      border-radius: 3px;
    }
    &_index {
      margin-top: 3px;
      width: 20px;
      height: 12px;
    }
  }

  .tags {
    width: 12%;
    display: flex;
    align-items: center;
    border-right: 1px solid #E1E4E8;
    .tag {
      height: 28px;
      margin: 0 auto;
      width: 65%;
      border-radius: 5px;
    }
  }

  // .avatars {
  //   padding: 0px 20px;
  //   display: flex;
  //   align-items: center;
  
  //   .avatar {
  //     position: relative;
  //     width: 34px;
  //     height: 34px;
  //     border: 1px white solid;
  //     border-radius: 50%;

  //     &:nth-child(2) {
  //       z-index: 2;
  //       right: 10px;
  //     }
  //     &:nth-child(3) {
  //       z-index: 3;
  //       right: 20px;
  //     }
  //     &:nth-child(4) {
  //       z-index: 4;
  //       right: 30px;
  //     }
  //     &:nth-child(5) {
  //       z-index: 5;
  //       right: 40px;
  //     }
  //   }
  // }
}
.header_fetching {
  height: 28px !important;
  border: none !important;
  background: none !important;
}
// .no_data {
//   text-align: center;
//   margin: 4rem 0px;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   height:  63vh;
//   font-family: Roboto;
//   .title {
//     font-size: 18px;
//     font-weight: 600;
//     line-height: 40px;
//   }
//   .subTitle {
//     font-size: 12px;
//     font-weight: 400;
//     line-height: 14px;
    
//   }
// }

.gradiant {
  background-color: rgb(238, 238, 238);
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238),
    rgb(245, 245, 245),
    rgb(238, 238, 238)
  );
  background-repeat: no-repeat;
  animation: 1.2s ease-in-out 0s infinite normal none running;
  animation-name: loading;
}
.reporting_item {
  display: flex;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 77px;
  padding: 16px 26px;

  &_infos {
    width: 25%;
    height: 100%;
    border-right: 1px solid #e1e4e8;

    &_header {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #29394d;
    }

    &_sub_header {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #6d7f92;
    }
  }

  &_state {
    width: 12%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e1e4e8;

    &_label {
      width: 65%;
      height: 28px;
      padding: 7%;
      background: #f1f2f4;
      border-radius: 4px;
      text-align: right;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      color: #6d7f92;
      margin: 5%;
    }
  }


  

  // &_avatars {
  //   padding: 0px 20px;
  //   text-align: center;
  //   justify-content: start;
  //   display: flex;
  //   align-items: center;
  //   width: 200px;

  //   .avatar {
  //     position: relative;
  //     &:nth-child(2) {
  //       z-index: 2;
  //       right: 10px;
  //     }
  //     &:nth-child(3) {
  //       z-index: 3;
  //       right: 20px;
  //     }
  //     &:nth-child(4) {
  //       z-index: 4;
  //       right: 30px;
  //     }
  //     &:nth-child(5) {
  //       z-index: 5;
  //       right: 40px;
  //     }
  //     &_exist {
  //       width: 34px;
  //       height: 34px;
  //       margin: auto;
  //       border: 1px white solid;
  //       border-radius: 50%;
  //       overflow: hidden;
  //       cursor: default;

  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: contain;
  //       }
  //     }

  //     &_not_exist {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       width: 34px;
  //       height: 34px;
  //       font-size: 12px;
  //       font-weight: 600;
  //       margin: auto;
  //       color: white;
  //       border: 1px white solid;
  //       border-radius: 50%;
  //       background-color: darkgray;
  //       cursor: default;

  //       & > span {
  //         margin: auto;
  //       }
  //     }
  //   }
  // }

  &:hover {
    background: #f1f2f4;
    z-index: 1;
    .item_actions {
      opacity: 1;
      transform: translateY(30%);
      .action {
        overflow: visible;
        box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
        border: 0.5px solid #c7e8fe;
      }
    }
  }
}
.items_header {
  height: 30px !important;
  background: none !important;
  border: none !important;
}
.label_header {
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  background: none;
  color: rgba(41, 57, 77, 1);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
