$tab-button-height: 52px;
.ttp-tab-container {
  padding: 0 2rem 0rem;
  position: relative;

  .ttp-tab-selector {
    position: relative;
    nav {
      position: absolute;
      top: -$tab-button-height/2;
      left: 0;
      right: 0;
      font-size: 15px;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      button {
        border: none;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        margin: 0 10px;
        cursor: pointer;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        min-width: 182px;
        padding: 0px 10px;
        height: $tab-button-height;
        font-family: $main-body-font;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        color: #18A0FB;
        outline: none;
        transition: box-shadow 0.2s, transform 0.2s;
        font-family: Roboto;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;

        &:hover {
          box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
          transform: scale(0.99);
        }
      }

      button.active {
        background: linear-gradient(180deg, #2495E1 0%, #18A0FB 100%);
        border: 1px solid #eef6ff;
        color: #fff;
      }
    }
  }
  & > div {
    padding: 1.5rem 0 0;
  }
  &.light {
    background: #fff;
  }
}
