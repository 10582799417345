.customedSelect {
    position: relative;
    z-index: 10;
    width: 87px;
    border: 1px solid #f1f2f4;
    height: 32px;
    border-radius: 30px;
    font-family: Roboto;
    font-style: normal;
    cursor: pointer;
    margin: 0;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background-color: #6D7F92;
    color: #FFF;

    &Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 0.5rem 3px 1.1rem;
      border: none;
      border-radius: 30px;

      .actions {
        margin-left: 8px;

        div {
          padding-left: 5px;
          right: 0;

          .action {
            width: 24px;
            height: 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 60%;

            i {
              font-size: 10px;
              font-weight: 600;
            }

          }
        }
      }
      &:hover {
        background: #E1E4E8;
      }
    }

    @keyframes drop1 {
      0% {
        height: 0px;
      }
      100% {
        height: 115px;
      }
    }

    &Body {
      padding: 10px 0;
      background: #F1F2F4;
      color: #3c4e64;
      border-top: 1px solid rgba(109, 127, 146, 0.1);
      animation-name: drop1;
      animation-duration: 0.2s;

      &Options {
        height: fit-content;
        // width: 460px;
        // padding: 10px 0px;
      }

      &Option {
        padding: 5px 20px;
        height: 30px;
        text-shadow: none;

        &:hover {
          cursor: pointer;
          background: #f3faff;
          color: #18a0fb;
        }
      }
    }

    &Opened {
      border-radius: 10px;
      box-shadow: 0px 2px 3px rgba(41, 57, 77, 0.1);

      i {
        transform: rotate(180deg);
        margin-bottom: 1px;
      }
      .customedSelectHeader {
        background: #6D7F92;
        color: #FFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
