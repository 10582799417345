
.card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  min-width: 19%;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 5px;
  margin: 0px 5px 10px;
  height: fit-content;
  padding: 20px 18px;
  width: 256px;

  .avatar {
    position: relative;
    &_exist {
      width: 65px;
      height: 65px;
      margin: auto;
      border: 1px white solid;
      border-radius: 50%;
      overflow: hidden;
      cursor: default;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_not_exist {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      font-size: 21px;
      font-weight: 600;
      margin: auto;
      color: white;
      border: 1px white solid;
      border-radius: 50%;
      background-color: darkgray;
      cursor: default;

      & > span {
        margin: auto;
      }
    }
    .tag-bottom {
      opacity: 0;
      position: absolute;
      padding: 5px;
      background: #FFF;
      border-radius: 5px;
      border: 1px solid #D8DDE2;
      right: 54px;
      bottom: 0px;
      height: 21px;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        transform: rotate(135deg);
        margin-right: 3px;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #29394D;
    margin: 10px auto 0px;
  }

  .switch {
    position: relative;
    height: 70px;
  }

  .total {
    position: absolute;
    right: 0;
    top: 9px;
    overflow: hidden;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding-right: 15px;
    line-height: 14px;
  }

  &_progress {
    height: 55px;
    overflow: hidden;
    display: flex;
    padding: 0px 10px;
    font-family: 'Roboto';
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border: none;
    transition: height 0.2s linear;
    margin-top: 10px;

    &_container {
      width: 100%;
      height: 28px;
      border-radius: 3px;
      background: #E1E4E8;
    }
    &_bar {
      height: 28px;
      background: #06D9B1;
      border-radius: 3px;
    }
    &_index {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
      flex-direction: row;
      font-weight: 500;

      span {
        &:nth-child(2) {
          color : #6D7F92!important;
          margin-right: 5px;
          font-weight: 500;
          margin-left: 3px;
        }
      }
    }
  }

  &_actions {
    height: 0px;
    display: flex;
    overflow: hidden;
    padding-top: 0px;
    justify-content: center;
    transition: height 0.2s linear;
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      vertical-align: middle;
      position: relative;
      width: 2.3rem;
      height: 2.3rem;
      overflow: hidden;
      border-radius: 50%;
      margin-left: -8px;
      text-transform: capitalize;
      cursor: pointer;
      background: #ffffff;
      color: #18a0fb;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

      :global(.icon),
      svg {
        cursor: pointer;
        color: #18a0fb;
        font-weight: 500;
        font-size: 16px;
        margin: auto;
        width: 20px;

        * {
          fill: #18a0fb;
        }

        &:global(.icon_setting) {
          height: 17px;
        }
      }
      &:hover {
        background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
        bottom: 0.2rem;
        z-index: 1;
        svg {
          color: #ffffff;

          * {
            fill: #ffffff;
          }
        }
      }

      a {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    & div div {
      font-size: 0.7rem;
      font-weight: 400;
    }
  }

  &_infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 11px;
    border-bottom: 1px solid #E1E4E8;

    span {
      line-height: 19px;
      &:first-child {
        font-weight: 400;
        font-size: 12px;
        color: #6D7F92;
      }

      &:nth-child(2) {
        font-weight: 700;
        font-size: 16px;
        color: #29394D;
      }
    }
  }

  &_counters {
    display: flex;
    height: 60px;
    .counter {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;

      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      text-align: center;
      color: #6D7F92;

      span {
        &:first-child {
          font-weight: 700;
          font-size: 14px;
          color: #DE4848;
        }
      }
      &:first-child  {
        border-right: 1px solid #E1E4E8;
        span {
          &:first-child {
            color: #02AF8E;
          }
        }
      }
    }
  }

  &:hover {
    background: #f1f2f4;
    z-index: 1;
    .card_progress {
      height: 0px;
      .action {
        overflow: visible;
        box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
        border: 0.5px solid #c7e8fe;
      }
    }
    .total {
      height: 0px;
    }
    .card_actions {
      height: 55px;
      padding-top: 5px;
      .action {
        overflow: visible;
        box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
        border: 0.5px solid #c7e8fe;
      }
    }
  }
  &:hover{
    .alert {
      width: 170px;
      justify-content: flex-end;
      .message {
        width: 152px;
        display: block;
      }
      svg {
        margin: 0px 5px;
      }
    }
    .avatar {
      .tag-bottom {
        opacity: 1;
      }
    }
  }
}
.disabled {
  opacity: 0.5;
}
.alert {
  display: flex;
  opacity: 1!important;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 22px;
  height: 20px;
  border-radius: 5px;
  background: rgba(235, 87, 87, 0.10);
  // transition: width 0.2s linear;
  overflow: hidden;
  .message {
    display: none;
    width: 152px;
    color: #EB5757;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
    text-align: end;
  }
}

.display {
  opacity: 1!important;
}
