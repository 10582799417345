.count_item {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 11.5px;
    font-weight: 400;
    color: #3c4e64;

    & p {
        padding: 0px 7px;
        display: inline-block;
        background: #f1f2f4;
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        margin: 0;
    }
}

.bg_F8F9FA {
    .ttp-select__control {
        background: #F8F9FA!important;
    }
}
