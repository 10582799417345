
.item {
  display: flex;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 5px;
  margin-bottom: 4px;
  height: 112px;
  padding: 16px 26px;
  position: relative;

  &_infos {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #E1E4E8;

    &_header {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #29394D;
      text-transform: uppercase;
    }
  
    &_sub_header {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      align-items: center;
      color: #6D7F92;
    }
  }

  &_progress {
    width: 230px;
    padding: 0px 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #E1E4E8;
    &_info {
      display: flex;
      justify-content: space-between;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #6D7F92;
    }

    &_container {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: rgba(109, 127, 146, 0.3);
    }
    &_bar {
      height: 4px;
      background: #29394d;
      border-radius: 3px;

    }
    &_index {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Roboto';
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      svg {
        margin: 4px 0 0 0;
        transform: rotate(180deg);
      }
    }
  }

  &_tags {
    width: 170px!important;
    display: flex;
    align-items: center;
    border-right: 1px solid #E1E4E8;
    .tag {
      height: 24px;
      margin: 0 auto;
      padding: 1px 7px;
      width: 90%;
      border-radius: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      display: flex;
      align-items: center;
      background: #F8F9FA;
      color: #6D7F92;

      span {
        font-weight: 500;
        margin-right: 5px;
      }

      svg {
        margin: 0px 5px;
      }
    }
    .tag-success {
      background: rgba(6, 217, 177, 0.1);
      color: #02AF8E;
    }
    .tag-danger {
      background: rgba(235, 87, 87, 0.1);
      color: #EB5757;
    }
    .set_tags {
      display: flex;
      margin-top: 5px;
      justify-content: space-around;
      width: 100%;

      .tag {
        width: 40%;
        background: #f3faff;
      }
    }
  }

  &_avatars {
    padding: 0px 20px;
    text-align: center;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    width: 150px;
  
    .avatar {
      position: relative;
      &:nth-child(2) {
        z-index: 2;
        right: 10px;
      }
      &:nth-child(3) {
        z-index: 3;
        right: 20px;
      }
      &:nth-child(4) {
        z-index: 4;
        right: 30px;
      }
      &:nth-child(5) {
        z-index: 5;
        right: 40px;
      }
      &:nth-child(6) {
        z-index: 6;
        right: 50px;
      }
      &:nth-child(7) {
        z-index: 7;
        right: 60px;
      }
      &:nth-child(8) {
        z-index: 8;
        right: 70px;
      }
      &:nth-child(9) {
        z-index: 9;
        right: 80px;
      }
      &:nth-child(10) {
        z-index: 10;
        right: 90px;
      }
      &:nth-child(11) {
        z-index: 11;
        right: 100px;
      }
      &_exist {
        width: 34px;
        height: 34px;
        margin: auto;
        border: 1px white solid;
        border-radius: 50%;
        overflow: hidden;
        cursor: default;
    
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    
      &_not_exist {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        font-size: 12px;
        font-weight: 600;
        margin: auto;
        color: white;
        border: 1px white solid;
        border-radius: 50%;
        background-color: darkgray;
        cursor: default;
    
        & > span {
          margin: auto;
        }
      }
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    transition: all 0.2s linear;
    opacity: 0;
    transform: translateY(-3%);
    position: absolute;
    right: 26px;
    top: 25px;
    z-index: 10;
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: cover;
      vertical-align: middle;
      position: relative;
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;
      border-radius: 50%;
      margin-left: -8px;
      cursor: pointer;
      background: #ffffff;
      color: #18a0fb;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

      :global(.icon),
      svg {
        cursor: pointer;
        color: #18a0fb;
        font-weight: 500;
        font-size: 16px;
        margin: auto;
        width: 20px;

        * {
          fill: #18a0fb;
        }

        &:global(.icon_setting) {
          height: 17px;
        }
      }
      &:hover {
        background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
        bottom: 0.5rem;
        z-index: 1;
        svg {
          color: #ffffff;

          * {
            fill: #ffffff;
          }
        }
      }

      a {
        display: flex;
        flex: 1;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    & div div {
      font-size: 0.7rem;
      font-weight: 400;
    }
  }

  &:hover {
    background: #f1f2f4;
    z-index: 1;
    .item_actions {
      opacity: 1;
      transform: translateY(30%);
      .action {
        overflow: visible;
        box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
        border: 0.5px solid #c7e8fe;
      }
    }
  }

  .bg-success {
    .item_progress_bar {
      background: #06D9B1;
    }
    color: #06D9B1;
  }
  .bg-danger {
    .item_progress_bar {
      background: #DE4848;
    }
    color: #DE4848;
  }
  .bg-warning {
    .item_progress_bar {
      background: #EA8124;
    }
    color: #EA8124;
  }

  &_opened {
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 0px;
    border: 0px;
  }
}
