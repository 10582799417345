.select_filter {
  .ttp-select__menu {
    .ttp-select__option {
      height: 40px;
      padding: 8px 12px ;
      display: flex;
      align-items: center;
    }
  }
}

.ttp-select__multi-value__label {
  padding: 8px!important;
}

.ttp-select__single-value {
  height: 20px;
  padding: 8px;
}