.content-body {
  .filter-frame {
    padding-bottom: 10px;
    border-bottom: 1px solid #E1E4E8;
    .principal-line {
      display: flex;
      align-items: center;
      height: 32px;
      justify-content: space-between;

      .interval-date-frame {
        display: flex;
        margin-right: 20px;
        align-items: center;
        width: 400px;

        label {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #6D7F92;
        }
        .date-range {
          width: 325px;
          display: flex;
          height: 100%;
          align-items: center;
          background: #F1F2F4;
          border-radius: 30px;
          padding: 0px 15px;
          margin-left: 10px;
          svg {
            margin-right: 9px;
          }
          > div {
            width: 115px;
            padding: 0px 10px;
          }
          .start-date {
            border-right: 1px solid #E1E4E8;
            margin-right: 10px;
            padding-right: 4px;
          }
          .p-calendar {
            width: 115px;
          }

          .p-calendar .p-inputtext {
            background: #F1F2F4;
            border: none;
            height: 28px;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.02em;
            border-radius: 50px;
            color: #6D7F92;

            &:hover {
            background: #E1E4E8;
            }
            &:focus {
              background: #E1E4E8;
              border-color: none;
              box-shadow: none
            }
          }
          &_action {
            height: 16px;
            width: 16px!important;
            border-radius: 50%;
            background: #6D7F92;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px!important;
            margin-left: 9px;
            cursor: pointer;
            svg {
              margin: 0px;
            }
          }
          .calendar-fill {
            .p-inputtext {
              background: #6D7F92;
              color: #FFF;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.24px;
              &:hover {
               background: #6D7F92;
               cursor: pointer;
              }
            }
          }
          &_fill {
            background: #E1E4E8;
          }
        }
      }
      .mini-tabs-frame {
        width: 360px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 190px;

        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #6D7F92;
        text-transform: uppercase;

        border-radius: 30px;
        background: #F1F2F4;

        > div {
          width: 120px;
          height: 100%;
          background: #F1F2F4;
          padding: 9px;
          border-radius: 30px;
          cursor: pointer;

          &:hover {
            background: #E1E4E8;
          }
        }

        &_active {
          color: #F1F2F4;
          background: #6D7F92!important;

        }
      }
      .filter-button {
        outline: none;
        width: 106px;
        height: 32px;
        padding: 9px 17px;
        background: #F1F2F4;
        border: none;
        border-radius: 30px;
        transition: all 0.3s;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #6D7F92;
        text-transform: uppercase;
      }
    }
    .additional-line {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      margin-top: 10px;

      .tag {
        height: 24px;
        min-width: 80px;
        border-radius: 5px;
        padding: 5px 6px 5px 6px;
        margin: 0px 2px;
        background: #F1F2F4;
        color: #6D7F92;
        text-align: center;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
      }
      .active {
        background: #6D7F92;
        color: #F1F2F4;
        font-weight: 600;
      }
      .disabled {
        opacity: 0.7;
        cursor:not-allowed;
      }
    }
  }
}
.tva_bilan_content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.switch-category-modal-control {
  border-radius: 7px;
  background-color: #fff;

  .switch-category-modal {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    border-radius: 7px;

    .modal-alert {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #18a0fb;
      background-color: rgba(#18a0fb, .1);
      padding: 20px;
      border-radius: 7px;
      width: 100%;
    }
  
    .category-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;

      .category-button {
        border: 0;
        outline: 0;
        width: fit-content;
        border-radius: 10px;
        background-color: transparent;
        color: #6D7F92;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.02em;
        transition: background-color 0.3s;
        cursor: pointer;
  
        &:hover {
          color: #18a0fb;
        }
      }
    }
  }
}