.matrix_container {
  width: 100%;
  // overflow-x: scroll;
}
.table-container {
  // width: 1326px;
  .table {
    overflow-x: scroll;
    font-size: 12px;
    &_header {
      display: flex;
      &_cellule {
        height: 48px;
        padding: 6px 0px;
        color: #fff;
        line-height: 14px;
        background-color: #3c4e64;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border-radius: 4px;
        margin: 0px 1px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        & > span {
          &:nth-child(2) {
            font-size: 9px;
          }
        }
      }
    }
    &_body {
      .row {
        display: flex;
        margin-top: 4px;
        cursor: pointer;

        .table_body_cellule {
          min-height: 40px;
          padding: 10px 0px;
          color: #29394d;
          background-color: #dee2e6;
          display: flex;
          justify-content: space-around;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          border-radius: 4px;
          margin: 0px 1px;

          .list-detail {
            margin: 0px 19px;
            & > div {
              display: flex;
              margin-bottom: 5px;
              width: 170px;
              .min-list {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 7px 4px 0px 5px;
                width: 100%;
                span {
                  text-align: start;
                  line-height: 12px;
                }
                .tag {
                  height: 20px;
                  width: 20px;
                  border-radius: 3px;
                  border: 1px solid #d9d9d9;
                  background: #fafafa;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                > div {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .active {
        .table_body_cellule {
          background-color: #CDE7FC
        }
      }
    }
  }
}
