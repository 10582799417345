.file-reader-iframe {
  iframe {
    z-index: 5;
    overflow-y: hidden;
    overflow-x: auto;
    position: fixed;
    top: 4.5rem;
    border: 1px solid rgba(0, 0, 0, 0.06);
    width: 85%;
    height: 90%;
    background: white;
    border-radius: 4px;
    margin-bottom: 50px;
    right: 8%;
  }

  .iframe_loading,
  .iframe_not_found {
    z-index: 7;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .iframe_close {
    position: fixed;
    top: 3.5rem;
    right: 6.5%;
    width: 38px;
    height: 38px;
    background: #fff;
    border: 1px solid #f1f2f4;
    border-radius: 50%;
    cursor: pointer;
    z-index: 6;

    svg {
      position: absolute;
      left: 11px;
      top: 11px;
    }
  }
}
