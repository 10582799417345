.dynamic_page {
  .resizable {
    position: relative;
    // transition: nth($transitions, 2);
    width: 100%;

    :global(.ttp-tab-container),
    :global(.rmodal__center) {
      padding: 0 !important;
    }

    :global(.ant-skeleton-title) {
      width: 300px;
    }

    :global(.ant-table-wrapper) {
      max-width: none !important;
    }
  }
  .resized {
    width: 70% !important;
    position: relative;
  }

  .ripple_loading {
    text-align: center;
    margin-top: 8rem;
  }

  .customed_select {
    width: 121px;
    z-index: 3;
    background: #6d7f92;
    text-transform: uppercase;
    color: #ffffff;
    height: 32px;
    border: 1px solid #f1f2f4;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    font-family: Roboto;
    margin: 0 3px;
    // width: 102px;
    text-align: center;
    line-height: 16px;
    padding: 0 5px;

    &_header {
      display: flex;
      font-weight: 500;
      align-items: center;
      padding: 3px 3px 3px 12px;
      align-items: center;
      justify-content: space-between;
      border: none;
      text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.5);

      .year_actions {
        padding: 3px;
        margin-left: 15px;

        div {
          right: 0;
          padding-left: 5px;

          .year_action {
            height: 24px;
            width: 24px;
            border-radius: 60%;
            text-align: center;

            i {
              color: #ffffff;
              font-weight: 600;
              font-size: 10px;
            }

            &:hover {
              background: red;
            }
          }
        }
      }
    }

    @keyframes drop1 {
      0% {
        height: 0px;
      }
      100% {
        height: 115px;
      }
    }

    &_body {
      padding: 10px 0;
      background: white;
      animation-name: drop1;
      border-top: 1px solid rgba(109, 127, 146, 0.1);
      color: #3c4e64;
      animation-duration: 0.2s;

      &__options {
        width: 460px;
        height: fit-content;
        padding: 10px 0px;
      }

      &_option {
        padding: 5px 20px;
        height: 30px;
        text-shadow: none;

        &:hover {
          cursor: pointer;
          background: #f3faff;
          color: #18a0fb;
        }
      }
    }

    &_opened {
      border-radius: 30px;
      //height: fit-content;
      box-shadow: 0px 2px 3px rgba(41, 57, 77, 0.1);

      i {
        transform: rotate(180deg);
        margin-bottom: 1px;
      }
    }
  }

  .cell_container {
    span {
      display: flex;
      justify-content: center;
      align-items: first baseline;
      font-size: 11.5px;

      p {
        margin-bottom: 0;
        margin-right: 2px;
      }
    }
  }
  .collaborator_duration {
    display: flex;
    justify-content: center;
    align-items: first baseline;
    font-size: 11.5px;
  }
  .avatar {
    &_exist {
      width: 3em;
      height: 3em;
      margin: auto;
      border: 1px white solid;
      border-radius: 50%;
      overflow: hidden;
      cursor: default;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_not_exist {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3em;
      height: 3em;
      margin: auto;
      color: white;
      border: 1px white solid;
      border-radius: 50%;
      background-color: darkgray;
      cursor: default;

      & > span {
        margin: auto;
      }
    }
  }
}
