.large-content {
  .p-carousel[pr_id_1] .p-carousel-item {
    flex: 0 !important;
  }

}
// .p-carousel-items-container {
//   align-items: end;
//   justify-content: center;
// }

.tab_badge {
  width: 200px; /* Ajustez cette valeur à la largeur souhaitée */
  white-space: nowrap; /* Empêche le retour à la ligne */
  overflow: hidden; /* Cache le texte qui dépasse la largeur du conteneur */
  text-overflow: ellipsis; /* Ajoute des points de suspension à la fin du texte */
}

.container{
  margin: 0 auto;
}

.export_btn {
  padding: 8px 24px;
  height: 44px;
  background-color: #fff;
  color: #3498db;
  border: 1px solid rgba(199, 232, 254, 0.5);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  border-radius: 30px;
  font-family: Roboto;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  z-index: 3;
}
