
.page_header {
  height: 96px;
  text-transform: uppercase;
  background: #f3faff;
  border-radius: 0px 0px 10px 10px;
  margin: 0 20px;
  display: flex;
  margin-bottom: 1rem;
  z-index: 0;

  &__icon {
    display: flex;
    width: 9%;
    justify-content: flex-end;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 60px;
      width: 1px;
      background-color: #ffffff;
      box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
    }
    img, svg {
      height: auto;
      width: 46px;
      padding-right: 12px;
      margin: 18px 0;
    }

  }

  &__info {
    width: 51%;
    display: flex;
    align-items: center;
    padding-left: 34px;

    * {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      margin: 0;
    }

    h3 {
      font-size: 24px;
      line-height: 34px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: #3c4e64;
    }
  }

  &__children {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5%;
    text-transform: initial;

    button {
      svg * {
        fill: #18A0FB;
      }
    }
  }
}
.title_forfait{
    height: 37px;
    text-transform: uppercase;
    background: #f3faff;
    border-radius: 0px 0px 10px 10px;
    margin: 0 20px;
    display: flex;
    margin-bottom: 1rem;
    z-index: 0;
  .title_info {
    // width: 20%;
    display: flex;
    align-items: center;
    padding-left: 34px;

    * {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      margin: 0;
    }

    h3 {
      font-size: 15px;
      line-height: 34px;
    }

    p {
      font-size: 8px;
      line-height: 18px;
      color: #3c4e64;
    }
  
}
}

.ligth_page_header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background: #f3faff;
  text-transform: uppercase;
  border-bottom: 0.5px solid #C7E8FE;
  padding: 0px 82px;

  &_left {
    display: flex;
  }
  &_back {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &_info {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    div {
      color: #18A0FB;
      margin-right: 5px;
    }
    span {
      color: #29394D;
    }
  }

  &_children {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: initial;

    button {
      svg * {
        fill: #18A0FB;
      }
    }
  }
}

.container {
  padding: 20px 60px 120px;
  margin: 0 auto;

  .result_label {
    margin: 5px 2px 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29394D;
    span {
      font-weight: 500;
    }
  }
}
