$cd-filter-width: 340px;
$main-body-font: 'Segoe UI', Helvetica, sans-serif;

#ttp-horizontal-filter {
  padding-bottom: 5px;
  margin: 10px 0;
  border-bottom: 1px solid #E1E4E8;

  select {
    text-transform: uppercase;
  }

  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    transition: all 0.3s;

    .filter-button {
      outline: none;
      width: 106px;
      height: 32px;
      padding: 9px 17px;
      background: #F1F2F4;
      border: none;
      border-radius: 30px;
      transition: all 0.3s;
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;


      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #6D7F92;
      text-transform: uppercase;

      .filter-icon * {
        fill: #6d7f92;
      }

      .icon {
        margin-left: 1.5rem;
        font-size: inherit;
      }
      span {
        width: 20px;
        height: 14px;
        color: #6D7F92;
        background: #FFF;
        border-radius: 50px;
        font-size: 12px;
        font-weight: 500;
      }
      &:hover {
        background: #E1E4E8;
      }
    }
  }
  .filter-form {
    display: flex;
    flex-flow: wrap;
    align-items: baseline;
    text-transform: uppercase;
    margin: auto;
    border-radius: 4px 0px 4px 4px;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    position: relative;
    padding: 10px 10px 3rem;
    z-index: 2;
    margin-bottom: 2rem;
    margin-top: 12px;

    &-enter {
      opacity: 0;
      transform: translateY(-2.5rem);
    }

    &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 300ms, transform 300ms, height 0.3s;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transform: translateY(-2.5rem);
      transition: opacity 300ms, transform 300ms, 0.3s;
    }

    span {
      position: relative;
      display: inline-block;
    }

    .close-button {
      position: absolute;
      right: 0;
      font-size: 1.2em;
      line-height: 1;
      background-color: white;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .ttp-select {
      span[aria-live="assertive"] {
        display: none;
      }
    }

    .element-frame {
      width: 25%;
      padding: 0px 10px;
      margin-top: 10px;
    }
  }
  label {
    font-family: $main-body-font;
    margin-top: 0.5rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 6px;
    color: #0a0a0a;
    text-transform: none;
    span.lbl {
      padding: 9px;
    }
  }

  &.is-fixed {
    .filter-wrapper {
      position: fixed;
      background: #fefefe;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      border-radius: 0;
    }
    &.ttp-medium-9 {
      .filter-wrapper {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
  .filter-close {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background: #f1f2f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    z-index: -1;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &.ttp-horizontal-filter__open {
    .filter-button {
      background-color: #6D7F92;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      svg * {
        fill: #ffffff;
      }
      &:hover {
        background-color: #6D7F92!important;
        color: #ffffff!important;
      }
    }

    .filter-form {
      background: #fafbfb;
    }

    .empty-box .ttp-select__control {
      background-color: #fff;
    }

    &.light .filter-form {
      background-color: #fff;
      .empty-box .ttp-select__control {
        background: #fafbfb;
      }
    }
  }

  .DateRangePicker {
    transform: translateX(-0.6rem);
    padding-left: 0.6rem;
    width: calc(100% + 0.6rem);

    text-transform: uppercase;
    color: #6d7f92;

    .DateRangePickerInput {
      border: none;
      background-color: inherit;

      .DateInput {
        width: 45%;

        .DateInput_input {
          margin: 0;
          width: 100%;
          padding: 11px 11px 9px;
          box-shadow: none;
          border-radius: 4px;
          border: 1px solid hsl(0, 0%, 80%);
          height: 38px;
          &__focused {
            outline: 0;
            background: #fff;
            border: 2px solid #2684ff;
          }
          &::placeholder {
            // color: $slate-gray;
            text-transform: uppercase;
            font-size: 0.8rem;
          }
        }
      }
    }

    .CalendarMonth_caption {
      padding-top: 30px;
    }

    .DateRangePickerInput_arrow_svg {
      // fill: $slate-gray;
      height: 15px;
    }

    .DateRangePickerInput_clearDates {
      margin: 0;
    }

    .dark {
      .DateRangePickerInput_clearDates {
        background-color: #fafbfb;
      }
    }

    .CalendarDay__selected_span {
      background: #1779bace;
    }

    .CalendarDay__selected {
      background: #14679e;
    }

    .CalendarDay__selected:hover {
      background: #1779ba;
    }

    .CalendarDay__selected_span,
    .CalendarDay__selected,
    .CalendarDay__selected:hover {
      border: 1px solid #14679e;
      color: white;
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
      background: #1779babe;
      border: none;
      color: white;
    }
  }
  &.dark {
    .DateRangePickerInput {
      background: #fafbfb;
    }
    .empty-box,
    .empty-box input {
      background: #fff;
      .ttp-select {
        &__control {
          background: #fff;
        }
      }
    }
    .empty-date-picker {
      .DateInput {
        background: #fff;
      }
    }
  }
}

.ttp_filter_group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .group_label {
    margin: 20px 10px 0px;
    width: 100%;
    position: relative;


    &::before {
      border-top: 1px solid #e5e7eb;
      position: absolute;
      display: block;
      top: 50%;
      left: 0;
      width: 100%;
      content: "";
    }
    label {
      background: #ffffff;
      padding: 0 0.5rem;
      position: relative;
      z-index: 1;
      font-size: 16px!important;
      font-weight: 500!important;
      text-transform: lowercase;
      color: #0a0a0a!important;
    }
  }
  .element-frame {
    margin-top: 0px!important;
  }
}

.customed_select {
  z-index: 10;
  width: 87px;
  border: 1px solid #f1f2f4;
  height: 32px;
  border-radius: 30px;
  font-family: Roboto;
  font-style: normal;
  cursor: pointer;
  margin: 0;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0.5rem 3px 1.1rem;
    border: none;
    border-radius: 30px;

    .actions {
      margin-left: 8px;

      div {
        padding-left: 5px;
        right: 0;

        .action {
          width: 24px;
          height: 24px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60%;

          i {
            font-size: 10px;
            font-weight: 600;
          }

        }
      }
    }
  }

  @keyframes drop1 {
    0% {
      height: 0px;
    }
    100% {
      height: 115px;
    }
  }

  &_body {
    padding: 10px 0;
    background: #F1F2F4;
    color: #3c4e64;
    border-top: 1px solid rgba(109, 127, 146, 0.1);
    animation-name: drop1;
    animation-duration: 0.2s;

    &__options {
      height: fit-content;
      width: 460px;
      padding: 10px 0px;
    }

    &_option {
      padding: 5px 20px;
      height: 30px;
      text-shadow: none;

      &:hover {
        cursor: pointer;
        background: #f3faff;
        color: #18a0fb;
      }
    }
  }

  &_opened {
    border-radius: 10px;
    box-shadow: 0px 2px 3px rgba(41, 57, 77, 0.1);

    i {
      transform: rotate(180deg);
      margin-bottom: 1px;
    }
    .customed_select_header {
      background: #6D7F92;
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}