.iframe {
  width: 100%;
  height: calc(100vh - 64px);
  z-index: 0;
  box-shadow: none;
  border: none;
  background-color: #fcfcfc;
  position: relative;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 99999;
}
.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #18a0fb;
  border-color: #18a0fb transparent #18a0fb transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nl_icon {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 60px;
  right: 6px;
  top: 157px;

  background: #29394d;
  border-radius: 8px;
  border: none;
  z-index: 1;
  cursor: pointer;
  &.show {
    animation-duration: 4s;
    animation-name: show-animation;
    animation-fill-mode: forwards;
  }
  &.hide {
    animation-duration: 5s;
    animation-name: hide-animation;
    animation-fill-mode: forwards;
  }
}
.close_icon {
  color: #c7e8fe;
  z-index: 1;
  &.show {
    animation-duration: 2s;
    animation-name: show-animation;
    animation-fill-mode: forwards;
  }
  &.hide {
    animation-duration: 2s;
    animation-name: hide-animation;
    animation-fill-mode: forwards;
  }
}
@keyframes hide-animation {
  from {
    right: 0px;
    opacity: 1;
  }
  to {
    right: -200px;
    opacity: 0;
  }
}

@keyframes show-animation {
  from {
    right: -200px;
    opacity: 0;
  }
  to {
    right: 6px;
    opacity: 1;
  }
}