/* reset css  */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  // color: $new-main-color;
  font-family: $font-family-base;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d8d8d;
  font-weight: 500;
  background: #fafbfb;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//sun editdor
.sun-editor {
  border: 0 !important;
}

.sun-editor-editable {
  padding: 16px 10px !important;
}
.sun-editor-editable,
.editorBox {
  font-family: $font-family-sans-serif !important;
  color: #29394d !important;
  font-size: 16px !important;
  line-height: 22px !important;
  background-color: transparent !important;

  p {
    margin-bottom: 16px !important;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 1rem 0 !important;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h3 {
    font-size: 20px;
    line-height: 26px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  h5 {
    font-size: 14px;
    line-height: 22px;
  }
  h6 {
    font-size: 14px;
    line-height: 20px;
  }
  table {
    width: 100% !important;
  }
  blockquote {
    color: #8a8a8a !important;
    border-width: 0 0 0 1px;
    border-color: #cacaca;
  }
}
.ttp-helpeditor {
  .sun-editor .se-toolbar {
    position: sticky;
    top: -2rem;
    background-color: #fff !important;
    outline: none !important;
    z-index: 100 !important;
  }

  .sun-editor .se-wrapper {
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 4px;
  }
  .sun-editor .se-wrapper .se-placeholder {
    padding-left: 10px !important;
  }

  .sun-editor .se-btn:enabled:focus,
  .sun-editor .se-btn:enabled:hover {
    background-color: rgba(109, 127, 146, 0.21) !important;
  }

  .sun-editor .se-btn-module-border {
    background-color: #f1f2f4 !important;
  }

  .sun-editor .se-menu-list li {
    margin: 0 1px !important;
  }

  .sun-editor .se-btn {
    color: #6d7f92 !important;
    background-color: #f1f2f4 !important;
  }

  .sun-editor .se-btn:enabled.active {
    color: #4592ff !important;
  }

  .sun-editor .se-btn-list:disabled,
  .sun-editor .se-btn:disabled,
  .sun-editor button:disabled {
    background-color: #fafafa !important;
  }
}

// styles for the slider in chaines page
.slick-slide {
  margin: 0px 8px;
}
.slick-list {
  margin: 0px -8px;
  padding-left: 8px;
}
@media screen and (max-width: 375px) {
  .slick-list {
    padding-left: 20px;
  }
}
$slick-font-path: "./fonts/" !default;
$slick-font-family: $body-font-family;
$slick-loader-path: "./" !default;
$slick-arrow-color: $h-color !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "<" !default;
$slick-next-character: ">" !default;
$slick-dot-character: "." !default;
$slick-dot-size: 4rem !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
.slick-dots {
  position: absolute;
  bottom: 0px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      background-color: $main-medium-gray;
      border-radius: 50%;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);

      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button {
      // background-color: $main-blue;
      box-shadow: none;
      //background-color: $portal-new-blue;
      // background-color: #db6939;
      padding: 0px 10px;
      border-radius: 25px;
      position: relative;
      left: -5px;
    }
  }
}

.page-content {
  background: #f4f7f9;
  min-height: calc(100vh - 110px);
}

.fadePage {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 105;
  background: rgba(41, 57, 77, 0.4);
}
.sidebar {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 106;
  transform: translateX(100%);
  transition: transform 100s;
  overflow-y: hidden;
  font-family: Roboto;
  font-style: normal;

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
  &_header {
    width: 100%;
    height: 80px;
    background: #F8F9FA;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    &_infos {
      width: 626px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        margin-right: 20px;
      }
      .action {
        width: 29px;
        height: 29px;
        border-radius: 5px;
        color: #FFF;
        background: #18A0FB;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
    &_action {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #F1F2F4;
      }
    }
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none!important;
}