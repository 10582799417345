//  ***** Balance Sheet DataTable *****//

tbody tr:nth-child(even) {
  background-color: inherit;
}

.ant-table-wrapper {
  font-family: Roboto, "Source Sans Pro", sans-serif;
  margin: auto;
}

.ant-table-small {
  border: none;
}

.ant-table-small > .ant-table-content .ant-table-header {
  background-color: $tab-container-bg;
}

.ant-table-fixed {
  background-color: $tab-container-bg;
}

.ant-table table {
  border-collapse: separate;
  margin-bottom: 0;
}

.ant-table-row-level-0 {
  background-color: $tab-container-bg !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: $level-0-color;

  .balance-sheet-accounts-code {
    background-color: $level-0-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-1-bg;
    margin-top: 3px;

    .balance-sheet-td-percentage {
      background: $level-0-percentage-bg;
    }
  }

  &.expanded-row-level-0 span:nth-child(3) {
    background-color: $level-0-bg;
  }

  //&.expanded-row-level-0 .balance-sheet-accounts-code {
  //  background: rgba(199, 232, 254, 0.5);
  //  color: #2495E1;
  //}

  &.expanded-row-level-0 .balance-sheet-td-component {
    background-color: $level-0-bg;
  }
}

.ant-table-row-level-0 span:nth-child(3) {
  background-color: $level-1-bg;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  width: 93%;
  border-radius: 5px;
  margin-top: 3px;
  margin-left: 3px;
}

.ant-table-row-level-1 {
  background-color: $tab-container-bg !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $level-1-color;

  .balance-sheet-accounts-code {
    background: $level-1-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-1-bg;

    .balance-sheet-td-percentage {
      background: $level-1-percentage-bg;
    }
  }

  .balance-sheet-td-component.balance-sheet-key-indicator {
    background-color: $key-indicator-bg;
    //background-color: #F3FAFF;
  }

  .balance-sheet-td-component.balance-sheet-key-indicator-treasury {
    background-color: #fff0b7;
    font-weight: 600;
  }

  .balance-sheet-td-component.balance-sheet-key-indicator-treasury-bold {
    //background-color: #e8f3ff;
    background-color: $key-indicator-bg;
    font-weight: 600;
  }
}

.ant-table-row-level-1 span:nth-child(3) {
  background-color: $level-1-bg;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  width: 89%;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -45%;
  }

  &.balance-sheet-key-indicator {
    background-color: $key-indicator-bg;
  }

  &.balance-sheet-key-indicator-treasury {
    background-color: #fff0b7;
    font-weight: 600;
  }

  &.balance-sheet-key-indicator-treasury-bold {
    //background-color: #e8f3ff;
    background-color: $key-indicator-bg;
    font-weight: 600;
  }
}

.ant-table-row-level-2 {
  background-color: $tab-container-bg !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $level-2-color;

  .balance-sheet-accounts-code {
    background: $level-2-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-2-bg;

    .set_tags {
      display: flex;
      flex-direction: column;
    }
    .balance-sheet-td-percentage {
      background: $level-2-percentage-bg;
    }
  }
}

.balance-sheet-label-wrap {
  white-space: nowrap;
  text-indent: 0px;
  display: flex;
  overflow: hidden;
  cursor: default;
  transition: all 0.5s;
}

.ant-table-row-level-2 span:nth-child(3) {
  background-color: $level-2-bg;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  width: 84%;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -50%;
  }
}

.ant-table-row-level-3 {
  background-color: $tab-container-bg !important;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: $level-3-color;

  .balance-sheet-accounts-code {
    background: $level-3-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-3-bg;

    .balance-sheet-td-percentage {
      background: $level-3-percentage-bg;
    }
  }
}

.ant-table-row-level-3 span:nth-child(3) {
  background-color: $level-3-bg;
  padding: 10px 20px 10px 20px;
  display: inline-block;
  width: 362px;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -30%;
  }
}

.ant-table-row-level-4 {
  background-color: $tab-container-bg !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: $level-4-color;

  .balance-sheet-accounts-code {
    background: $level-4-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-4-bg;

    .balance-sheet-td-percentage {
      background: $level-4-percentage-bg;
    }
  }
}

.ant-table-row-level-4 span:nth-child(3) {
  background-color: $level-4-bg;
  padding: 10px 0 10px 20px;
  display: inline-block;
  width: 342px;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -40%;
  }
}

.ant-table-row-level-5,
.ant-table-row-level-6 {
  background-color: $tab-container-bg !important;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: $level-4-color;

  .balance-sheet-accounts-code {
    background: $level-4-percentage-bg;
  }

  .balance-sheet-td-component {
    background-color: $level-4-bg;

    .balance-sheet-td-percentage {
      background: $level-4-percentage-bg;
    }
  }
}

.ant-table-row-level-5 span:nth-child(3) {
  background-color: $level-4-bg;
  padding: 10px 0 10px 20px;
  display: inline-block;
  width: 323px;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -20%;
  }
}

.ant-table-row-level-6 span:nth-child(3) {
  background-color: $level-4-bg;
  padding: 10px 0 10px 20px;
  display: inline-block;
  width: 300px;
  border-radius: 5px;

  .balance-sheet-label-wrap:hover {
    text-indent: -20%;
  }
}

.ant-table-thead {
  background-color: none !important;
}

.ant-table-column-title {
  text-align: center;
  background-color: $thead-bg;
  padding: 9px 10px 7px;
  font-size: 13px;
  display: flex;
  width: 99%;
  align-items: center;
  justify-content: center;
  height: 43px;
  margin-bottom: -5px;
  border-radius: 5px !important;

    .sorter {
      margin-left: 5px;
      padding-top: 5px;
      cursor: pointer;
    }

    &_sub {
      display: flex;
      align-items: center;
      div {
        width: 80%;
      }
      span {
        margin-left: 10px;
        background: #F8F9FA;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 2px 4px;
        min-width: 50px;
        border-radius: 5px;
        color: #6d7f92;
      }
    }
}

.ant-table-fixed-left .ant-table-header .ant-table-column-title {
  width: 423px;
  margin-left: 25px;
  border-radius: 5px !important;
}

.ant-table-fixed-left .ant-table-header tr {
  height: 40px !important;
  max-height: 49px;
}

.ant-table-fixed-left table {
  background: $tab-container-bg;
}

.ant-table-thead > tr > th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: white;
  background: #fff;
  text-align: center;
  padding: 9px 9px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0 !important;
  border-bottom: none;
}

.ant-table-thead > tr > th .ant-table-header-column {
  width: 100%;
}

.balance-sheet-td-component {
  padding: 10px 0 10px 0px;
  display: inline-block;
  margin-top: 3px;
  width: 99%;
  text-align: center;

  .balance-sheet-td-value {
    display: inline-block;
    text-align: end;
    width: 55%;
    position: relative;

    .balance-sheet-td-attachment {
      position: absolute;
      left: -3px;
      font-size: 11px;
      display: flex;
      width: 20px;
      height: 20px;
      background: transparent;
      border-radius: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #29394d;
      transition: all 0.5s ease;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        background: #c9d0d7;
        font-size: 12px;
        i {
          transform: rotate(360deg);
          transition: all 0.5s ease;
        }
      }
    }
  }

  .balance-sheet-td-percentage {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;

    display: inline-block;
    //text-align: end;
    min-width: 62px;
    border-radius: 5px;
    padding: 4px;
    margin: -4px 0px -4px 9px;
    color: #6d7f92;

    //background: #4cd964;
  }
}

.balance-sheet-accounts-code {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  color: #6d7f92;
  vertical-align: middle;
  padding: 2px 6px 1px 6px;
}

.ant-table-header th:nth-child(2) .ant-table-column-title,
td:nth-child(2) > .balance-sheet-td-component {
  //border-radius: 5px 0px 0px 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ant-table-header th:last-child .ant-table-column-title,
td:last-child > .balance-sheet-td-component {
  //border-radius: 0px 5px 5px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-table-row-level-0[data-row-key="0_RR"] ~ .ant-table-row-level-1 {
  .balance-sheet-td-component.balance-sheet-key-indicator {
    background-color: $level-1-bg;
  }
}

.ant-table-row-level-0[data-row-key="0_RR"]
  ~ .ant-table-row-level-1
  span:nth-child(3) {
  &.balance-sheet-key-indicator {
    background-color: $level-1-bg;
  }
}

.ant-table-row {
  margin: 2px 0px;
}

.ant-table-row-expand-icon {
  background: #e5e8eb;
  color: #6d7f92;
}

.ant-table-row-level-0 {
  .ant-table-row-expand-icon {
    margin-top: 35px!important;
  }
}
.ant-table-row-level-1 {
  .ant-table-row-expand-icon {
    margin-top: 16px!important;
    margin-left: 4px;
  }
  span:nth-child(3).balance-sheet-key-indicator-treasury-bold {
    margin-top: 3px;
  }
}

.ant-table-row-level-2 {
  .ant-table-row-expand-icon {
    margin-top: 12px!important;
    margin-left: 9px;
  }
  span:nth-child(3).balance-sheet-key-indicator-treasury-bold {
    margin-top: 3px;
  }
}

.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 6.65px;
  border-radius: 0.5px;
  width: 1.5px;
}
.ant-table-row-expand-icon::before {
  top: 6.65px;
  right: 3px;
  border-radius: 0.5px;
  left: 3px;
  height: 1.5px;
}
.ant-table-fixed-left .ant-table-header .ant-table-column-title {
  width: 423px;
  margin-left: 25px;
}
.ant-table-cell {
  background: #fff;
  font-size: 13px;
}
.ant-spin-nested-loading {
  z-index: 1;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}
.balance-sheet-key-indicator-treasury-bold {
  display: flex !important;
  align-items: center;
}
.balance-sheet-key-indicator-title {
  width: 94%;
  height: 45px;
  border-radius: 3px;
  align-items: center;
  background: #f1f2f4;
  margin: 3px 5px 0 22px;
}

.end-components {
  background: #c9d0d7!important;
  border-radius: 3px;
}

.last-components {
  background: rgb(245, 225, 191)!important;
  border-radius: 3px;
}

.ant-table-column-title_sub {
  width: 100%;
}
