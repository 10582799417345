.ripple_loading {
  text-align: center;
  margin: 5rem auto;
  display: flex;
  justify-content: center;
  height: 250px;
}

.no_data {
  text-align: center;
  margin: 4rem 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  font-family: Roboto;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    
  }
}

.ttp-tab-container {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.content-body {
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  border-radius: 5px;
  margin: 0px auto;
  padding: 24px;
  width: 88%;

  &_global {
    padding: 20px 87px 120px;
    margin: 0 auto;
    background: transparent;
    width: 100%;
    border: 0px;
  }
}

.large-content {
  width: 94%;
}
