.page_header {
  height: 96px;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
  margin: 0 20px;
  display: flex;
  margin-bottom: 1rem;
  z-index: 0;
}

.toggle_btn {
  position: fixed;
  background-size: cover;
  vertical-align: middle;
  top: 50%;
  right: 0;
  transform: translate(75%, -50%);
  background-color: #fff;
  padding: 5px 0 5px 12px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  background: #ffffff;
  color: #18a0fb;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  overflow: hidden;
  border-radius: 50px 0 0 50px;
  transition: ease-in 0.3s;

  svg {
    color: #fff;

    * {
      fill: #fff;
      stroke: #29394d;
    }
  }

  span {
    width: 70%;
    font-size: 12px;
  }

  &:hover {
    background: linear-gradient(180deg, #18a0fb 0%, #2495e1 100%);
    transform: translate(20%, -50%);
    svg {
      color: #fff;

      * {
        fill: #18a0fb;
        stroke: #fff;
      }
    }

    span {
      color: #ffffff;
    }
  }
}

.sidebar {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 20px;
  width: 55%;
  animation: slide alternate .3s;

  .sidebar_content {
    padding: 10px 20px;
    height: 90%;
    overflow-y: auto;

    .sidebar_content__list {
      .sidebar_content__list_head {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        padding: 10px 0;
      }

      .sidebar_content__sub_head {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        list-style: circle;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: .5px;
          background-color: #29394d50;
          transition: background-color 0.3s ease;
        }
      }
    }

    .forfait__remarks_list__head {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
    }

    .forfait__remarks_list__item {
      margin-left: 5px;
    }
  }
}

.editable_zone {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .icon_info_circle {
    display: none;
    font-size: .7rem;
  }

  .editable_text {
    border: 0;
    outline: 0;
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;
  
    &:focus { 
      outline: 1px solid #29394d;
      background-color: #f1f2f4;

      + .icon_info_circle {
        display: inline-block;
      }
    }
  }
}

.forfait__textarea {
  position: relative;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  color: #29394d;
  margin-bottom: 20px;

  .forfait__textarea_label {
    background-color: #fff;
    transform: translate(10px, 7px);
    padding: 0 2px;
  }
  
  .forfait__textarea_textarea {
    border-radius: 7px;
    padding: 10px;
    width: 100%;
  }
}

.action_button {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  .action_button__item {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    width: 144px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
  }

  .button_save {
    background: rgb(6, 217, 177) !important;
    color: #ffffff;
  }

  .button_cancel {
    background: #6d7f9250 ;
    color: #29394d;
  }
}

.title_forfait {
  padding: 25px 50px;
  .title_info {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: normal;
    color: #29394d;
  }
}

@keyframes slide {
  from {
    width: 0;
  }

  to {
    width: 55%;
  }
}
