.calendar_container {
  .container {
    .palette {
      border-radius: 5px;
      border: 0.5px solid rgb(229, 232, 235);
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .color {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        div {
          height: 20px;
          width: 20px;
          border-radius: 5px;
          margin-right: 5px;
          border: 0.5px solid rgb(229, 232, 235);
        }
      }
    }
    .caroussel {
      display: flex;
      margin: 20px 0px 5px;
      .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        width: 84%;
        .now {
          color: #29394D;
          font-size: 16px;
          align-items: center;
          display: flex;
        }
        .next, .prev {
          color: #6D7F92;
          display: flex;
          align-items: center;
          svg {
            margin: 0px 10px;
          }
        }
        .next {
          svg {
            transform: rotate(180deg);
          }
        }
      }
      .label {
        font-size: 12px;
        color: #29394D;
        width: 236px;
      }
    }
    .calendar_table {
      table {
        border-collapse: collapse;
      }
      .ant-table-cell-row-hover {
        background: #fff !important;
      }
      .ant-table-row-level-0 {
        background: transparent!important;
        &:hover {
          border-bottom: 1px solid #1E1E1E;
        }
      }
      .minim-componenent {
        border-radius: 3px;
        margin: 2px;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-table-column-title {
        background: transparent;
        color: #29394D;
      }
      .ant-table-thead>tr>th {
        border-top: 1px solid #1E1E1E;
      }
      .ant-table-cell-fix-left-last {
        border-top: none!important;
      }
      .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background: transparent;
      }
      .user-info {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        .avatar {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          border-radius: 50%;
          border: 2px solid #E1E4E8;
          background-size: cover;
          background-position: top center;
        }

        .infos {
          display: flex;
          flex-direction: column;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          width: 81%;

          div {
            display: flex;
            align-items: center;
            width: 100%;
            &:first-child {
              line-height: 18px;
            }
            span {
              font-size: 12px;
              line-height: 16px;
              color: #6D7F92;
              width: fit-content;
              &:nth-child(3) {
                background-color: transparent;
                margin: 0;
                padding: 0px;
              }
            }
            .dot {
              min-width: 6px;
              max-width: 6px;
              height: 6px;
              border-radius: 50px;
              background-color: #b2bcc6;
              margin: 0px 5px;
            }
          }
        }
      }
      .warning_tag {
        padding: 5px 10px;
        color: #EB5757;
        background: rgba(235, 87, 87, 0.1);
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        width: fit-content;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.tooltip-frame {
  width: 223px;
  padding: 12px;
  .color {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #29394D;
    div {
      height: 20px;
      width: 20px;
      border-radius: 5px;
      margin-right: 15px;
      border: 0.5px solid rgb(229, 232, 235);
    }
  }
  .title {
    font-size: 16px;
    color: #29394D;
    font-weight: 500;
    margin-top: 10px;
  }
  .message {
    display: flex;
    align-items: center;
    margin: 15px 0px 10px;
    font-size: 12px;
    color: #6D7F92;

    svg {
      margin-right: 10px;
    }
    span {
      margin-right: 5px;
      margin-top: 5px;
      width: 80%;
    }
  }
  .minim_message {
    margin: 15px 0px 10px;
    font-size: 12px;
    color: #6D7F92;

    svg {
      margin-right: 10px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    .action {
      width: 48%;
      padding: 0px 10px;
    }
    .success {
      color: #02AF8E;
      border-color: #06D9B1;
      * {
        fill : #02AF8E;
      }
    }
    .danger {
      color: #EE4C19;
      border-color: #FC5D2B;
      * {
        fill : #EE4C19;
      }
    }
  }
  .action {
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    border-color: #6D7F92;
    color: #6D7F92;
    width: 100%;
  }
}
.ant-tooltip-inner {
  border: 1px solid #E1E4E8;
  border-radius: 5px;
}


.reporting-calendar-table {
  .ant-table-cell {
    padding: 1px;

    .balance-sheet-td-component.minim-componenent {
      border-radius: 3px;
      height: 49px;
      width: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
