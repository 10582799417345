  .colomn {
    display: flex;
    align-items: center;
    justify-content:none;
    .labled-tag {
      span{
        background: rgb(255, 255, 255);
        font-size: 12px;
        height: 50px;
      }
      div{
        border-left: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #6d7f92;
        height: 28px;
        width: 72px;
        border-radius: 5px;
        padding: 6px 5px;
        background: #f1f2f4;
        text-align: end;
        border-left: none;
        justify-content: flex-end;
        
      }
    }
    .tag {
      margin: 0 auto;

    }
    .labled-tag {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .tag {
        margin: 15px 0px 0px;
        // padding-left: 5px;
      }
    }
    .no-border {
      border: 0px;
      width: 150px;
      .tag {
        border: 0px;
        padding: 0px;
        & > div {
          margin: 0px;
        }
      }
    }
  }

  .vol_label {
    position: relative;
    z-index: 0;
  }

  .vol_label::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -6.5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 6px solid #6d7f9250;
    pointer-events: none;
  }

  .clients_panel {
    position: absolute;
    left: 0px;
    margin-top: -5.5px;
    padding: 26px 20px;
    background-color: #FFF;
    width: 300px;
    border: 1px solid #dfdfdf;
    border-left: 0;
    border-right: 0;
    // box-shadow: 7px 8px 15px #00000040;

    .expand_arrow {
      position: absolute;
      left: 50%;
      bottom: -12.5px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      border: 1px solid #e0e0e0;
      cursor: pointer;
      // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 5px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
  }

.prev_arrow, .next_arrow {
  position: absolute;
  top: calc(50% - 40px);
  z-index: 3;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.prev_arrow {
  left: 260px;
}

.next_arrow {
  right: 0;
  transform: rotate(180deg);
}

.date-range {
  width: 325px;
  display: flex;
  height: 100%;
  align-items: center;
  background: #F1F2F4;
  border-radius: 30px;
  padding: 0px 15px;
  margin-left: 10px;
  svg {
    margin-right: 9px;
  }
  > div {
    width: 115px;
    padding: 0px 10px;
  }
  .start-date {
    border-right: 1px solid #E1E4E8;
    margin-right: 10px;
    padding-right: 4px;
  }
  .p-calendar {
    width: 115px;
  }

  .p-calendar .p-inputtext {
    background: #F1F2F4;
    border: none;
    height: 28px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.02em;
    border-radius: 50px;
    color: #6D7F92;

    &:hover {
    background: #E1E4E8;
    }
    &:focus {
      background: #E1E4E8;
      border-color: none;
      box-shadow: none
    }
  }
  &_action {
    height: 16px;
    width: 16px!important;
    border-radius: 50%;
    background: #6D7F92;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px!important;
    margin-left: 9px;
    cursor: pointer;
    svg {
      margin: 0px;
    }
  }
  .calendar-fill {
    .p-inputtext {
      background: #6D7F92;
      color: #FFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      &:hover {
       background: #6D7F92;
       cursor: pointer;
      }
    }
  }
  &_fill {
    background: #E1E4E8;
  }
}
.separator {
  width: 1.3px;
  height: 30px;
  background-color: #E1E4E8;
  margin: 0 15px;
}
.download-button {
  background: #06d9b1;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 172px;
  height: 44px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  // margin-left: auto;
  cursor: pointer;
}

.form-control {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 15px;

  .field-control {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 7px;

    .label {
      color: #6D7F92;
    }

    .field {
      width: 100%;
      border: 1px solid #6D7F92;
      padding: 5px;
    }
  }
}