.filter-button_open {
  background-color: #6D7F92!important;
  color: #ffffff!important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  span {
    width: 20px;
    height: 14px;
    color: #6D7F92;
    background: #FFF;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
  }
  svg{
    *{
      fill: #fff;
    }
  }
}
.board-frame {
  display: flex;
  .header {
    border-radius: 5px;
    margin: 2px;
    height: 39px;
    display: flex;
    justify-content: space-between;
  }
  .column-board {
    margin: 0px 9px;
    width: 24%;
    border-radius: 5px;
    padding: 3px;
    // height: fit-content;
    .header {
      border-radius: 5px;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 10px;
      text-transform: capitalize;
    }
    
  }
  .TODO_column {
    background: rgba(109, 127, 146, 0.05);
    min-height: 200px;
    .header {
      background: rgba(109, 127, 146, 0.10);
    }
  }
  .WIP_column {
    background: rgba(234, 129, 36, 0.05);
    .header {
      background: rgba(234, 129, 36, 0.10);
      color:  #D87318;
    }
  }
  .VALIDATION_column {
    background: rgba(24, 160, 251, 0.05);
    .header {
      color: #3B8AD5;
      background: rgba(24, 160, 251, 0.10);
    }
  }
  .DONE_column {
    background: rgba(6, 217, 177, 0.05);
    .header {
      color: #02AF8E;
      background: rgba(6, 217, 177, 0.10);
    }
  }
}

.card-board {
  border-radius: 5px;
  border: 1px solid #F1F2F4;
  background: #FFF;
  padding: 13px 9px 10px;
  color: #29394D;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  margin: 13px 4px 0px 4px;
  height: 228px;
  display: flex;
  flex-direction: column;
  position: relative;

  &_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 5px;
  }
  .avatar-frame {
    color: #29394D;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .details {
      display: flex;
      flex-direction: column;
      margin-left: 7px;
    }
    .avatar_not_exist {
      width: 44px;
      height: 44px;
      span{
      color: #FFF!important;
      font-size: 18px!important;
      }
    }
    span {
      color: #6D7F92;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      &:first-child {
        color: #29394D;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  &_sub_title {
    font-size: 12px;
    line-height: 15px;
  }
  &_infos {
    color: #6D7F92;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
    line-height: 15px;

    span {
      color: #29394D;
    }
  }
  &_description {
    margin: 10px 0px;
    overflow: hidden;
    color: #6D7F92;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
  }
  &_lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 21px;
    left: 0;
    width: 100%;
    padding: 10px;
    .avatars {
      text-align: center;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      width: 33%;
    
      .avatar {
        position: relative;

        $elements: 15;
        @for $i from 0 to $elements {
          &:nth-child(#{$i + 1}) {
            z-index: $i+1;
            right: $i*7px;
          }
        }
        &_exist {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          border-radius: 50%;
          border: 2px solid #E1E4E8;
          background-size: cover;
          background-position: top center;
        }
      
        &_not_exist {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 28px;
          font-size: 12px;
          font-weight: 600;
          margin: auto;
          color: white;
          border: 2px solid #F1F2F4;
          border-radius: 50%;
          background-color: darkgray;
          cursor: default;
      
          & > span {
            margin: auto;
            color: white;
          }
        }
      }
    }
    .tag {
      border-radius: 5px;
      background: #F1F2F4;
      padding: 4px 10px;
      color: #DE4848;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.24px;
    }
  }
  &_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    .ago {
      color: #6D7F92;
      font-size: 12px;
      font-weight: 400;
    }
    .flags {
      .flag {
        color: #6D7F92;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
      }
    }
  }
}